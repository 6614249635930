import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import Select from 'react-select'

import './SubtaskCreation.css'

const formatSubtasks = (existingSubtasks) => {
  return existingSubtasks ?
    existingSubtasks.map((subtask, index) => {
      return {
        label: subtask.name,
        value: index,
      }
    }) : []
}

const hasNoResults = (formattedSubtasks, searchQuery) => {
  return formattedSubtasks.filter((formattedSubtask) => formattedSubtask.label.toLowerCase().startsWith(searchQuery.toLowerCase())).length === 0
}

const SubtaskCreationDialog = ({ taskName, taskIndex, onAddSubtask, onNewSubtask, isOpen, onClose, existingSubtasks }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const formattedSubtasks = formatSubtasks(existingSubtasks)

  const handleQueryChange = (newValue) => {
    setSearchQuery(newValue)
  }

  const handleCreateSubtaskReactSelect = (event) => {
    if (event.keyCode === 13 && hasNoResults(formattedSubtasks, searchQuery)) {
      event.preventDefault()
      onNewSubtask(taskIndex, searchQuery)
      setSearchQuery('')
    }
  }

  const handleSelect = (event, other) => {
    if (event !== null) {
      const selectedSubtask = existingSubtasks[event.value]
      onAddSubtask(taskIndex, selectedSubtask)
      setSearchQuery('')
    }
  }

  return (
    <Dialog
      className="bp3-dark dialog-div"
      enforceFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={true}
      title={'Subtask Creation: ' + taskName}
    >
      <Select
        className="select-input-subtask-creation"
        noOptionsMessage={() => 'Press \'enter\' to create a new subtask'}
        onKeyDown={handleCreateSubtaskReactSelect}
        defaultInputValue=""
        inputValue={searchQuery}
        value={null}
        onChange={handleSelect}
        options={formattedSubtasks}
        onInputChange={handleQueryChange}
        theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral0: '#394b59',
                neutral90: '#fff',
                primary: '#222',
                primary25: '#30404D',
                primary50: '#30404D',
                primary75: '#30404D'
              }
            })}
        isClearable
        autoFocus
      />
    </Dialog>
  )
}

export default SubtaskCreationDialog
