import React, { Component } from 'react'
import Avatar from '../Avatar/Avatar'
import Moment from 'react-moment'

import DateUtils from '../../DateUtils'

import './Userinfo.css'

class Userinfo extends Component {

  state = {
    user: this.props.user
  }

  componentWillReceiveProps(newProps) {
    this.setState({ user: newProps.user })
  }

  render() {
    const lastLogin = (this.state.user) ? this.state.user.lastLoginAt : null
    const avatarUrl = (this.state.user) ? this.state.user.avatarUrl : null
    const displayName = (this.state.user) ? this.state.user.displayName : null
    const email = (this.state.user) ? this.state.user.email : null
    const role = (this.state.user) ? this.state.user.role : null

    return (
      <div>
        <div className="user-principal-info-container">
          <Avatar round={true} src={avatarUrl} />
          <div className="name-email-container">
            <div className="name-role-container">
              <h2 className="bp3-heading"> {displayName} </h2>
              <div>
                <div valign="top">
                  {(role === 'admin') ?
                    (<div className="bp3-tag bp3-minimal">admin</div>)
                    :
                    (<div></div>)}

                  {(role === 'manager') ?
                    (<div className="bp3-tag bp3-minimal">manager</div>)
                    :
                    (<div></div>)}
                </div>
              </div>
            </div>

            <div className="email-container">Email: {email}</div>
            <div>
              Last login: {(lastLogin) ? <Moment date={lastLogin} format={DateUtils.LONG_DATE_FORMAT} /> : <span>N/A</span>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Userinfo