import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Firebase } from '../../firebase/firebase'
import { Icon, Spinner } from '@blueprintjs/core'

import './Uploader.css'

class Uploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      url: '',
      progess: false,
      error: false
    }
  }

  handleOnDropFile = (files) => {
    const storage = Firebase.getInstance().getStorage()

    const image = files[0]
    const uploadTask = storage.ref(`images/${image.name}`).put(image)
    uploadTask.on('state_changed',
      (snapshot) => {
        this.setState({ progess: true })
      },
      (error) => {
        this.setState({ error: true })
      },
      () => {
        storage.ref('images').child(image.name).getDownloadURL().then((url) => {
          this.props.changeLogoUrl(url)
          this.setState({
            url: url,
            progess: false,
            error: false
          })
        })
      })
  }

  renderBox = () => {
    if (this.state.progess) {
      return (
        <div className="drop-file-container">
          <Spinner intent="primary" />
        </div >
      )
    }

    if (this.state.error) {
      return (
        <div className="drop-file-container">
          <Icon icon="error" intent="danger" />
        </div >
      )

    }

    return (
      <div className="drop-file-container">
        <h5 className="bp3-heading"> Drop file here or click to add file</h5>
        <Icon icon="cloud-upload" iconSize={40} />
      </div>
    )
  }

  render() {
    return (
      <div>
        <Dropzone onDrop={this.handleOnDropFile}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {this.renderBox()}
            </div>
          )}
        </Dropzone>
      </div>
    )
  }
}

export default Uploader