import React, { Component } from 'react'
import { Button, Classes, Dialog } from '@blueprintjs/core'

import { toTitleCase } from '../../Utils'
import Userinfo from '../Userinfo/Userinfo'

class SupervisorsDialog extends Component {

  // Used props : supervisors, title, isOpen, handleClose(), handleSave().

  handleSave = (supervisor) => {
    this.props.handleSave(supervisor)
    this.props.handleClose()
  }

  render() {
    const { supervisors, title, isOpen, handleClose } = this.props

    return (
      <Dialog
        className={Classes.DARK}
        onClose={handleClose}
        title={toTitleCase(title)}
        isOpen={isOpen}
        style={{ width: '700px' }} // Larger dialog to fit <Userinfo /> cards correclty.
      >
        <div className={Classes.DIALOG_BODY}>
          {
            supervisors.map((supervisor, index) =>
              <div key={index} className="users-card-space bp3-card bp3-elevation-2" onClick={() => this.handleSave(supervisor)} style={{ cursor: 'pointer' }}>
                <Userinfo user={supervisor} />
              </div>
            )
          }
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent="danger" onClick={handleClose}>Cancel</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default SupervisorsDialog