import React, { Component } from 'react'
import Avatar from '../Avatar/Avatar'
import moment from 'moment'
import { Button, Icon, Position, Tooltip } from '@blueprintjs/core'

import './MessageBoard.css'

import DateUtils from '../../DateUtils'
import Comment from '../Comment/Comment'
import MessageEditor from '../MessageEditor/MessageEditor'

class MessageBoard extends Component {
  state = {
    messages: (this.props.messages) ? Object.keys(this.props.messages).map((i) => this.props.messages[i]) : [],
    user: this.props.user,
    onAddMessage: this.props.onAddMessage,
    onDeleteMessage: this.props.onDeleteMessage,
    messageText: '',
    togglePreview: false
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      messages: (newProps.messages) ? Object.keys(newProps.messages).map((i) => newProps.messages[i]) : [],
      onAddMessage: newProps.onAddMessage,
      user: newProps.user
    })
  }

  handlerUpdateMessageText = (message) => {
    this.setState({
      messageText: message
    })
  }

  handlerOnSaveMessage = () => {
    this.state.onAddMessage(this.state.messageText)
    this.setState({
      messageText: ''
    })
  }

  handlerOnDeleteMessage = (message) => {
    this.state.onDeleteMessage(message)
  }

  handlerTogglePreviewMessage = () => {
    this.setState({
      togglePreview: !this.state.togglePreview
    })
  }

  render() {
    const messages = this.state.messages
    const user = this.state.user
    let preview = null

    if (this.state.togglePreview) {
      preview = <div><Comment message={this.state.messageText} /></div>
    }

    return (
      <div>
        <div className="board-message">
          {(messages && messages.length > 0) ? (
            <div className="Message">
              {messages.map((message, index) => {
                return (
                  <div key={index} className="element-message">
                    <div className="bp3-card bp3-elevation-2 element-message">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td width="10px">
                              <Avatar round={true} src={message.user_avatar} size={30} />
                            </td>
                            <td>
                              <ul style={{ listStyleType: 'none' }}>
                                <li className="bp3-text-muted"><i>{message.user_display_name} Sended <Tooltip content={moment(message.date).format(DateUtils.LONG_DATE_FORMAT)} position={Position.TOP}>{moment(message.date).fromNow()}</Tooltip></i></li>
                              </ul>
                            </td>

                            {(user.role === 'admin') ? (
                              <td width="10px">
                                <Button icon="trash" onClick={() => this.handlerOnDeleteMessage(message)}></Button>
                              </td>) : null}
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <Comment message={message.text} />
                    </div>
                  </div>
                )
              })}
            </div>
          ) : null}

          {(user.role === 'admin') ? (
            <div className="bp3-card edit-message">
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50px" valign="top">
                      <Avatar round={true} src={(this.state.user) ? this.state.user.avatarUrl : ''} size={30} />
                    </td>
                    <td>
                      <p className="title-message"><Icon icon="inbox" iconSize={18} /> Write a Message</p>
                      <MessageEditor onSaveMessage={this.handlerOnSaveMessage} onMessageChange={this.handlerUpdateMessageText} />
                    </td>
                  </tr>
                </tbody>
              </table>
              {preview}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default MessageBoard