import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Intent } from '@blueprintjs/core'

import * as actions from '../../../store/actions'
import { getObjectValues } from '../../../Utils'
import AdminTitleInfo from '../../../components/AdminTitleInfo/AdminTitleInfo'

import './Settings.css'

class Settings extends Component {

  state = {
    configs: this.props.configs,
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      configs: newProps.configs,
    })
  }

  updateInputValue = (event) => {
    const newConfigs = this.state.configs
    newConfigs[event.target.id].value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    this.setState({
      configs: newConfigs
    })
  }

  saveConfig = (config) => {
    this.props.firebase
      .set('configs/' + config.id, config)
      .then(() => { })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  render() {
    return (
      <div>
        <AdminTitleInfo
          title="Settings"
          info="Systems preferences of the timesheet app. This settings are apply on all timesheets." />

        {this.state.configs.map((config, index) => {
          return (
            <div key={index} className="settings-input-space">
              <div className="bp3-form-group bp3-inline">
                <label className="bp3-label">
                  {config.name}
                </label>
                <div className="bp3-form-content">
                  {typeof config.value === 'boolean' ?
                    <input
                      id={config.id}
                      type="checkbox"
                      dir="auto"
                      onChange={this.updateInputValue}
                      onBlur={() => this.saveConfig(config)}
                      checked={config.value} /> :
                    <input
                      id={config.id}
                      className="bp3-input"
                      type="text"
                      dir="auto"
                      onChange={this.updateInputValue}
                      onBlur={() => this.saveConfig(config)}
                      value={config.value} />
                  }
                  <div className="bp3-form-helper-text">{config.desc}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  firebaseConnect((props) => [
    'configs',
  ]),
  connect((state, props) => ({
    configs: getObjectValues(state.firebase.data.configs),
  })),
  connect(mapDispatchToProps)
)(Settings)