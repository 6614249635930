import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_URL
}

export class Firebase {

  constructor() {
    this.firebase = firebase
    this.firebase.initializeApp(config)
    this.provider = new firebase.auth.GoogleAuthProvider()
    this.provider.setCustomParameters({
      prompt: 'consent'
    })
  }

  static getInstance() {
    if (!this.ref) {
      this.ref = new Firebase()
    }
    return this.ref
  }

  getFirebase() {
    return this.firebase
  }

  getStorage() {
    return this.firebase.storage()
  }
}