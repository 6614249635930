import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Intent } from '@blueprintjs/core'

import * as actions from '../../../store/actions'
import { getObjectValues } from '../../../Utils'
import AdminTitleInfo from '../../../components/AdminTitleInfo/AdminTitleInfo'
import AdminTasksTable from '../../../components/AdminTasksTable/AdminTasksTable'

class Projects extends Component {

  updateProject = (project) => {
    this.props.firebase
      .set(`tasks/projects/${project.id}`, project)
      .then(() => { })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  render() {
    return (
      <div>
        <AdminTitleInfo
          title="Projects"
          info="Lists of all projects in the system. They are displayed in timesheet of all users."
        />
        <AdminTasksTable
          tasks={this.props.projects}
          updateTask={this.updateProject}
          taskType="project"
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  firebaseConnect([
    'tasks/projects',
  ]),
  connect((state, props) => ({
    projects: state.firebase.data.tasks ? getObjectValues(state.firebase.data.tasks.projects) : [],
  })),
  connect(mapDispatchToProps)
)(Projects)