import * as actionTypes from './actionTypes'

export const clearToasts = () => ({ type: actionTypes.CLEAR_TOASTS })

// toast = { action, iconName, intent, message, onDismiss, timeout }
export const toast = (toastObject) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.ENQUEUE_TOAST,
    toast: toastObject,
  })
}