import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as actions from '../../../store/actions'
import { Popover, Menu, MenuItem, MenuDivider, Position } from '@blueprintjs/core'
import Avatar from '../../Avatar/Avatar'
import { Firebase } from '../../../firebase/firebase'

import './UserMenu.css'

class UserMenu extends Component {
  constructor(props) {
    super(props)
    this.firebase = Firebase.getInstance()
    this.props = props
  }

  state = {
    user: this.props.user
  }

  componentWillReceiveProps(newProps) {
    this.setState({ user: newProps.user })
  }

  handleClickLogout = (event) => {
    this.firebase.getFirebase().logout()
    this.props.resetState()
  }

  render() {
    return (
      <Popover content={<Menu>
        <Link className="menu-without-style" to="/profile"><div><MenuItem icon="user" text="Profile" /></div></Link>
        <Link className="menu-without-style" to="/achievements"><div><MenuItem icon="endorsed" text="Achievements" /></div></Link>
        <Link className="menu-without-style" to="/timesheet"><div><MenuItem icon="calendar" text="Timesheet" /></div></Link>
        <Link className="menu-without-style" to="/timesheet/expenses"><div><MenuItem icon="dollar" text="Expenses Reports" /></div></Link>
        <Link className="menu-without-style" to="/timesheet/summary"><div><MenuItem icon="timeline-bar-chart" text="Summary" /></div></Link>
        <MenuDivider />
        <MenuItem text="Logout" onClick={this.handleClickLogout} icon="log-out" />
      </Menu>} position={Position.BOTTOM}>
        <button className="bp3-button bp3-minimal">
          <Avatar size={16} round={true} src={this.state.user.avatarUrl} />
          <span className="bp3-minimal bp3-icon-caret-down bp3-align-right"></span>
        </button>
      </Popover>
    )
  }
}
const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(actions.resetState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)