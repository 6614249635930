import React, { Component } from 'react'
import Avatar from '../Avatar/Avatar'
import { orderDescendingArray } from '../../Utils'

class AchievementUsers extends Component {

  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      users: this.props.users,
      achievementslogs: this.props.achievementslogs,
    }
  }

  componentWillReceiveProps = (newProps) => {
    const state = this.state
    state.users = newProps.users
    state.achievementslogs = newProps.achievementslogs
    this.setState(state)
  }

  render() {
    let toplist = []
    const { users, achievementslogs } = this.state

    for (const user of users) {
      const achievements = achievementslogs.filter((item) => {
        return item.to_id === user.uid
      })
      if (achievements.length > 0) {
        toplist.push({ ...user, achievements, nbAchievements: achievements.length })
      }
    }
    toplist = orderDescendingArray(toplist, 'nbAchievements')

    return (
      <div>
        {
          toplist.map((user, index) => {
            return (
              <div key={user.id} style={{ paddingTop: '10px' }}>
                <div className="bp3-card bp3-elevation-2 element-message row">
                  <div className="col-3">
                    <Avatar size={60} round={true} src={user.avatarUrl} />
                  </div>
                  <div className="col-6">
                    <h2 className="bp3-heading">{user.displayName}</h2>
                  </div>
                  <div className="col-3">
                    <center>
                      <h1 className="bp3-heading">{user.achievements.length}<span className="bp3-icon bp3-icon-endorsed" style={{ paddingLeft: '10px' }}></span></h1>
                    </center>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default AchievementUsers