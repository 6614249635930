import React, { Component } from 'react'
import { Spinner as BSpinner, Intent } from '@blueprintjs/core'

class Spinner extends Component {
  render() {
    return (
      <BSpinner intent={Intent.PRIMARY}></BSpinner>
    )
  }
}

export default Spinner