import React, { Component } from 'react'
import { Button, Classes, Dialog } from '@blueprintjs/core'

import { toTitleCase } from '../../Utils'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

const propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  permissionValue: PropTypes.string
}

class AdminPermissionDialog extends Component {

  state = {
    permission: '',
    permissionValue: this.props.permissionValue,
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        permission: this.props.permission,
        permissionValue: this.props.permissionValue
      })
    }
  }

  onValueChanged = (event, field) => {
    this.setState({
      [field]: event.target.value,
    })
  }

  handleSave = () => {
    this.props.handleSave(this.state.permission, this.state.permissionValue)
    this.props.handleClose()
  }

  render() {
    const title = this.props.new ? 'New Permission' : 'Edit Permission'
    const message = this.props.new ? 'By default, this permission will be set to false for everyone.' : 'This will change the path for all roles.'
    const permission = this.state.permission || ''
    const permissionValue = this.state.permissionValue || ''

    const { isOpen, handleClose } = this.props
    return (
      <Dialog
        className={Classes.DARK}
        onClose={handleClose}
        title={toTitleCase(title)}
        isOpen={isOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          <h5 className="bp3-heading">{message}</h5>
          {this.props.new ?
            <label className="bp3-label bp3-inline">
              Name: <input className="bp3-input bp3-fill" type="text" dir="auto" value={permission} onChange={(e) => this.onValueChanged(e, 'permission')} />
            </label> : null}
          <label className="bp3-label bp3-inline">
            Path: <input className="bp3-input bp3-fill" type="text" dir="auto" value={permissionValue} onChange={(e) => this.onValueChanged(e, 'permissionValue')} />
          </label>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent="danger" onClick={handleClose}>Cancel</Button>
            <Button intent="success" onClick={this.handleSave}>Save</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

AdminPermissionDialog.propTypes = propTypes

export default AdminPermissionDialog