import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Link } from 'react-router-dom'
import { Menu, MenuDivider, Icon } from '@blueprintjs/core'

import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import Settings from './Settings/Settings'
import Users from './Users/Users'
import DefaultTasks from './DefaultTasks/DefaultTasks'
import Customers from './Customers/Customers'
import Projects from './Projects/Projects'
import Roles from './Roles/Roles'
import Reports from './Reports/Reports'

import './Admin.css'

export class Admin extends Component {

  constructor(props) {
    super(props)
    this.props = props
  }

  state = {
    user: this.props.user,
  }

  componentWillReceiveProps(newProps) {
    this.setState({ user: newProps.user })
  }

  render() {
    return (
      <div>
        <div className="bp3-elevation-2 leftsection-admin">
          <Menu>
            <Link className="bp3-menu-item" to="/admin"><Icon icon="settings" /> &nbsp;&nbsp;Settings</Link>
            <MenuDivider />
            <Link className="bp3-menu-item" to="/admin/users"><Icon icon="user" /> &nbsp;&nbsp;Users</Link>
            <Link className="bp3-menu-item" to="/admin/roles"><Icon icon="people" /> &nbsp;&nbsp;Roles</Link>
            <Link className="bp3-menu-item" to="/admin/defaults"><Icon icon="form" /> &nbsp;&nbsp;Default Tasks</Link>
            <Link className="bp3-menu-item" to="/admin/customers"><Icon icon="shop" /> &nbsp;&nbsp;Customers</Link>
            <Link className="bp3-menu-item" to="/admin/projects"><Icon icon="projects" /> &nbsp;&nbsp;Projects</Link>
            <MenuDivider />
            <Link className="bp3-menu-item" to="/admin/reports"><Icon icon="timeline-area-chart" /> &nbsp;&nbsp;Time Reports</Link>
          </Menu>
        </div>
        <div className="mainsection-admin">
          <Switch>
            <PrivateRoute authed={this.state.user} path="/admin" exact component={Settings} />
            <PrivateRoute authed={this.state.user} path="/admin/users" component={Users} />
            <PrivateRoute authed={this.state.user} path="/admin/defaults" component={DefaultTasks} />
            <PrivateRoute authed={this.state.user} path="/admin/customers" component={Customers} />
            <PrivateRoute authed={this.state.user} path="/admin/projects" component={Projects} />
            <PrivateRoute authed={this.state.user} path="/admin/roles" component={Roles} />
            <PrivateRoute authed={this.state.user} path="/admin/reports" component={Reports} />
          </Switch>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.firebase.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin))