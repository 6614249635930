import React, { Component } from 'react'
import { Button, Tooltip, Position } from '@blueprintjs/core'

class AchievementMain extends Component {

  state = {
    user: this.props.user,
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      user: newProps.user,
    })
  }

  render() {
    const { to_display_name, name, description, from_display_name } = this.props
    return (
      <div style={{ marginBottom: '20px', marginTop: '10px', marginLeft: '35px', marginRight: '15px' }}>
        <div className="row">
          <div className="col-11">
            <div className="bp3-text-muted">{to_display_name} win "{name}" badge!</div>
            <div><b>{description}</b></div>
            <div className="givenBy"><span style={{ fontSize: '11px' }}>Given by {from_display_name}</span></div>
          </div>
          {(this.props.user.role === 'admin') ? (
            <div className="col-1">
              <Tooltip content={'Delete this achievement'} position={Position.LEFT}>
                <Button icon="trash" onClick={() => this.props.onDeleteAchievement(this.props.id)}></Button>
              </Tooltip>
            </div>) : null}
        </div>
      </div >
    )
  }
}

export default AchievementMain