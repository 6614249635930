import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'

import './Tabs.css'

class Tabs extends Component {
  render() {
    const location = this.props.location.pathname
    const links = this.props.links
    return (
      <div className="bp3-tabs tabs-header">
        <div className="bp3-tab-list bp3-large" role="tablist">
          {(links) ? links.map((link, index) => {
            return (<div key={index} className="bp3-tab" role="tab" aria-selected={location === link.location}><Link to={link.location}>{link.title}</Link></div>)
          }) : null}
        </div>
        <div className="bp3-tab-panel" role="tabpanel">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withRouter(Tabs)