import React, { Component } from 'react'
import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import 'react-mde/lib/styles/css/react-mde-all.css'
import './MessageEditor.css'

const propTypes = {
  text: PropTypes.string,
  onMessageChange: PropTypes.func,
  onSaveMessage: PropTypes.func
}

const defaultProps = {
  showSaveButton: true
}

class MessageEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.text,
      tab: 'write'
    }
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    })
  }

  handlerValueChange = (value) => {
    this.setState({ value })
    this.props.onMessageChange(value)
  };

  handlerOnTabChange = (tab) => {
    this.setState({ tab })
  }

  handleOnSaveMessage = () => {
    this.props.onSaveMessage()
    this.setState({
      value: '',
      tab: 'write'
    })
  }

  renderSaveButton = () => {
    if (this.props.showSaveButton) {
      return (
        <Button
          className="editor-button"
          onClick={this.handleOnSaveMessage}
          text="Message"
          disabled={!this.state.value}
        />
      )
    }
    else {
      return null
    }
  }

  render() {
    const saveButton = this.renderSaveButton()

    const minHeight = 110
    return (
      <div className="message-container-editor">
        <ReactMde
          onChange={this.handlerValueChange}
          value={this.state.value}
          onTabChange={this.handlerOnTabChange}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(this.converter.makeHtml(markdown))
          }
          minEditorHeight={minHeight}
          minPreviewHeight={minHeight}
          selectedTab={this.state.tab}
        />
        {saveButton}
      </div>
    )
  }
}

MessageEditor.propTypes = propTypes
MessageEditor.defaultProps = defaultProps

export default MessageEditor