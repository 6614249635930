import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Note from '../Note/Note'
import { toTitleCase, hasSubtasks } from '../../Utils'

import './DailyTaskNote.css'

export const isMainTaskWithSubtasks = (timelog, subtaskIndex) => {
  return hasSubtasks(timelog) && subtaskIndex === null
}

const propTypes = {
  timelog: PropTypes.object,
  date: PropTypes.string,
  day: PropTypes.string,
  taskIndex: PropTypes.number,
  subtaskIndex: PropTypes.number,
  onTimeChange: PropTypes.func,
  onSaveTimelog: PropTypes.func,
  onUpdateTimelog: PropTypes.func
}

class DailyTaskNote extends Component {

  render() {
    const { timelog, date, day, taskIndex, subtaskIndex, onTimeChange, onUpdateTimelog, onSaveTimelog } = this.props

    const formattedIndexes = subtaskIndex === null ? taskIndex : `${taskIndex}_${subtaskIndex}`

    const hoursWorked = subtaskIndex === null ? timelog[day] : timelog.subtasks[subtaskIndex][day]

    return (
      <div className="daily-task-container">
        <div className="date-info-container">
          <h5 className="bp3-heading"> {`${toTitleCase(day)} ${date}`} </h5>
        </div>
        <div className="timelog-container">
          <input
            name={`${formattedIndexes}_${day}`}
            className="bp3-input input"
            value={hoursWorked}
            onChange={onTimeChange}
            disabled={isMainTaskWithSubtasks(timelog, subtaskIndex)}
            onBlur={() => onUpdateTimelog(timelog)}
            placeholder="🕒"
          />
        </div>
        <div className="note-container">
          <Note
            day={day}
            onUpdateTimelog={onUpdateTimelog}
            onSaveTimelog={onSaveTimelog}
            timelog={timelog}
            subtaskIndex={subtaskIndex}
          />
        </div>
      </div>
    )
  }
}

DailyTaskNote.propTypes = propTypes

export default DailyTaskNote
