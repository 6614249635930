import React, { Component } from 'react'
import { Button, Switch } from '@blueprintjs/core'
import { toTitleCase } from '../../Utils'

import AdminPermissionDialog from '../../components/AdminPermissionDialog/AdminPermissionDialog'
import PropTypes from 'prop-types'

const propTypes = {
  roleName: PropTypes.string,
  permissions: PropTypes.array,
  updatePermission: PropTypes.func,
  editPermission: PropTypes.func,
  getRoleValue: PropTypes.func
}

const defaultProps = {
  roleName: '',
  permissions: [],
}

class AdminRolesTable extends Component {

  state = {
    SelectedPermission: null,
    permissionValue: null,
    isOpen: false,
    isNew: false
  }

  onSwitchChanged = (event, index) => {
    this.props.updatePermission(this.props.roleName, Object.keys(this.props.permissions)[index], event.target.checked)
  }

  handleOpen = (permission) => {
    const permissionValue = this.props.getRoleValue(permission)
    this.setState({
      isOpen: true,
      SelectedPermission: permission,
      permissionValue,
      isNew: false
    })
  }
  handleClose = () => {
    this.setState({
      isOpen: false,
      SelectedPermission: null,
      permissionValue: null,
      isNew: false
    })
  }
  handleSave = (permission, value) => {
    this.props.editPermission(permission, value, this.state.isNew)
  }

  handleAdd = () => {
    const newPermission = ''
    this.setState({ isOpen: true, selectedPermission: newPermission, isNew: true })
  }

  render() {
    const permissions = Object.keys(this.props.permissions)
    const roleDisplayName = toTitleCase(this.props.roleName)
    return (
      <div style={{ marginTop: '15px' }}>
        <h3 className="bp3-heading">{roleDisplayName} Permissions</h3>
        <Button icon="add" text={'Add Permission'} intent="success" onClick={this.handleAdd} />
        <table className="bp3-html-table bp3-interactive bp3-html-table-striped" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '100%' }}>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              permissions.map((permission, index) => {
                return (
                  <tr key={index}>
                    <td>{permission}</td>
                    <td><Switch checked={this.props.permissions[permission]} onChange={(e) => this.onSwitchChanged(e, index)} /></td>
                    <td><Button icon="edit" onClick={() => this.handleOpen(permission)}></Button></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

        <AdminPermissionDialog
          isOpen={this.state.isOpen}
          handleClose={this.handleClose}
          handleSave={this.handleSave}
          permission={this.state.SelectedPermission}
          new={this.state.isNew}
          permissionValue={this.state.permissionValue}
        />
      </div>
    )
  }
}

AdminRolesTable.propTypes = propTypes
AdminRolesTable.defaultProps = defaultProps

export default AdminRolesTable