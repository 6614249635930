import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect, populate } from 'react-redux-firebase'
import { Button, Intent } from '@blueprintjs/core'
import Avatar from '../../../components/Avatar/Avatar'

import * as actions from '../../../store/actions'
import { getObjectValues } from '../../../Utils'
import Userinfo from '../../../components/Userinfo/Userinfo'
import AdminTitleInfo from '../../../components/AdminTitleInfo/AdminTitleInfo'
import SupervisorsDialog from '../../../components/SupervisorsDialog/SupervisorsDialog'

import './Users.css'

export class Users extends Component {
  state = {
    selectedUser: null,
    isOpen: false,
  }

  updateUserRole = (index, role) => {
    this.props.firebase
      .set('users/' + this.props.users[index].uid + '/role', role)
      .then(() => {
        this.props.onToast('info-sign', Intent.SUCCESS, 'User role saved.')
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  addSupervisor = (supervisor) => {
    this.props.firebase
      .set(`users/${this.state.selectedUser.uid}/supervisor`, supervisor.uid)
      .then(() => {
        this.props.onToast('info-sign', Intent.SUCCESS, 'Supervisor added.')
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  toggleUserActivation = async (user) => {
    const isActive = this.isUserActive(user)
    try {
      await this.props.firebase
        .set(`users/${user.uid}/active`, !isActive)
      if (!isActive) {
        this.props.onToast('info-sign', Intent.SUCCESS, 'Reactivated account.')
      } else {
        this.props.onToast('info-sign', Intent.SUCCESS, 'Deactivated account.')
      }

    } catch (error) {
      this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
    }
  }

  isUserActive = (selectedUser) => {
    return selectedUser.active !== undefined ? selectedUser.active : true
  }

  removeSupervisor = (user) => {
    this.props.firebase
      .ref(`users/${user.uid}/supervisor`)
      .remove()
      .then(() => {
        this.props.onToast('info-sign', Intent.SUCCESS, 'Supervisor removed.')
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handleOpen = (user) => this.setState({ isOpen: true, selectedUser: user });
  handleClose = () => this.setState({ isOpen: false, selectedUser: null });

  render() {
    return (
      <div>
        <AdminTitleInfo
          title="Users"
          info="Lists of all users in the system." />

        {
          this.props.users.map((user, index) => {
            return (
              <div key={index} className="users-card-space bp3-card bp3-elevation-2">
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="60%">
                        <Userinfo user={user} />
                      </td>
                      <td width="30%">
                        {
                          user.supervisor ?
                            <div>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Avatar size={50} round={true} src={user.supervisor.avatarUrl} />
                                    </td>
                                    <td>
                                      <h3 className="bp3-heading">{user.supervisor.displayName}</h3>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              < Button icon="remove" intent={Intent.DANGER} text="Remove supervisor" onClick={() => this.removeSupervisor(user)} />
                            </div>

                            :
                            <Button icon="add" intent={Intent.SUCCESS} text="Add supervisor" onClick={() => this.handleOpen(user)} />
                        }
                      </td>
                      <td width="30%">
                        {this.isUserActive(user) ?
                         <Button text="Deactivate" intent={Intent.DANGER} onClick={() => this.toggleUserActivation(user)} /> :
                         <Button text="Activate" intent={Intent.SUCCESS} onClick={() => this.toggleUserActivation(user)} />}
                      </td>
                      <td width="10%">
                        <div className="bp3-button-group bp3-vertical">
                          <Button text="Admin" intent={Intent.NONE} onClick={() => this.updateUserRole(index, 'admin')} />
                          <Button text="Manager" intent={Intent.NONE} onClick={() => this.updateUserRole(index, 'manager')} />
                          <Button text="User" intent={Intent.NONE} onClick={() => this.updateUserRole(index, 'user')} />
                          <Button text="Delete" intent={Intent.DANGER} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          })
        }
        <SupervisorsDialog
          title={'Add Supervisor'}
          isOpen={this.state.isOpen}
          handleClose={this.handleClose}
          handleSave={this.addSupervisor}
          // A user cannot be his own supervisor and must not have a 'user' role (must be 'manager' or 'admin')
          supervisors={this.props.users.filter((user) => user.role !== 'user' && user !== this.state.selectedUser)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

const populates = [
  { child: 'supervisor', root: 'users' }
]

export default compose(
  firebaseConnect((props) => [
    { path: 'users', populates }
  ]),
  connect((state, props) => ({
    users: getObjectValues(populate(state.firebase, 'users', populates)),
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(Users)
