import React, { Component } from 'react'
import { Dialog, Classes } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import isEqual from 'lodash/isEqual'

import DateUtils from '../../DateUtils'
import DailyTaskNote from '../DailyTaskNote/DailyTaskNote'

import './TaskNote.css'

const propTypes = {
  timelog: PropTypes.object,
  day: PropTypes.string,
  date: PropTypes.string,
  taskDescription: PropTypes.string,
  currentWeek: PropTypes.number,
  currentYear: PropTypes.number,
  taskIndex: PropTypes.number,
  onTimeChange: PropTypes.func,
  onSaveTimelog: PropTypes.func,
  isOpen: PropTypes.bool,
  days: PropTypes.array,
  onClose: PropTypes.func
}

const defaultProps = {
  isOpen: false,
  days: DateUtils.DAYS,
  onClose: () => null
}

class TaskNote extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.isOpen != null ? props.isOpen : false,
      timelog: props.timelog
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.timelog, this.props.timelog)) {
      this.setState({ timelog: this.props.timelog })
    } if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  handleOnCommentClick = () => {
    this.setState({ isOpen: true })
  }

  handleOnClose = () => {
    this.props.onClose()
    this.props.onSaveTimelog(this.state.timelog)
    this.setState({ isOpen: false, })
  }

  handleUpdateTimelog = (timelog) => {
    this.setState({ timelog })
  }

  render() {
    const { timelog, isOpen } = this.state
    const { taskDescription, currentWeek, currentYear, taskIndex, subtaskIndex, onSaveTimelog, onTimeChange, days } = this.props
    const currentDay = moment(currentYear, 'YYYY').add(currentWeek - 1, 'weeks').startOf('isoweek')
    return (
      <Dialog
        className="bp3-dark dialog-div"
        isOpen={isOpen}
        onClose={this.handleOnClose}
        canOutsideClickClose={true}
        title={'Notes: ' + taskDescription}
      >
        <div className={Classes.DIALOG_BODY}>
          {
            days.map((day, index) => {
              const date = currentDay.format(DateUtils.SHORT_DAY_AND_MONTH_FORMAT)
              currentDay.add(1, 'days')
              return (
                <DailyTaskNote
                  taskIndex={taskIndex}
                  subtaskIndex={subtaskIndex}
                  date={date}
                  day={day}
                  key={index}
                  timelog={timelog}
                  onSaveTimelog={onSaveTimelog}
                  onTimeChange={onTimeChange}
                  onUpdateTimelog={this.handleUpdateTimelog}
                />)
            })
          }
        </div>
      </Dialog>
    )
  }
}

TaskNote.propTypes = propTypes
TaskNote.defaultProps = defaultProps

export default TaskNote
