import * as actionsTypes from '../actions/actionTypes'
import moment from 'moment'

const initialState = {
  currentYear: moment().isoWeekYear(),
  currentWeek: moment().isoWeek(),
  user: null,
}

const goToWeek = (state, action) => {
  return {
    ...state,
    currentYear: action.year,
    currentWeek: action.week
  }
}

const selectUser = (state, action) => {
  return {
    ...state,
    user: action.user
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.TIMESHEET_GOTO_WEEK: return goToWeek(state, action)
    case actionsTypes.TIMESHEET_SELECT_USER: return selectUser(state, action)
    case actionsTypes.TIMESHEET_RESET_STATE: return initialState
    default: return state
  }
}

export default reducer