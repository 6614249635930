import React from 'react'
import moment from 'moment'
import { Tooltip } from '@blueprintjs/core'
import Holidays from 'date-holidays-parser'

import data from './customHolidays.json'

const hd = new Holidays(data)
hd.init('CA', 'QC')

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
const LONG_DATE_FORMAT = 'LL LTS'
const SHORT_DATE_FORMAT = 'YYYY-MM-DD'
const SHORT_DAY_AND_MONTH_FORMAT = 'D MMM'
const MIN_ISO_WEEK_NUMBER = 1

/**
* Converts a day number to a string.
*
* @method dayOfWeekAsString
* @param {Number} dayIndex
* @return {String} Day as string
*/
const dayOfWeekAsString = (dayIndex) => {
  return DAYS[dayIndex]
}

/**
 * Get the start and the end of the week.
 * @param {Date|moment} date
 */
const getWeekRange = (date) => {
  return {
    from: moment(date).startOf('isoWeek').toDate(),
    to: moment(date).endOf('isoWeek').toDate(),
  }
}

const getIsoWeeksInYear = (year) => moment(`${year}-01-01`).isoWeeksInYear()

const isPayday = (paydays, currentDay) => {
  return paydays && paydays.includes(currentDay.format(SHORT_DATE_FORMAT))
}

const isToday = (today, currentDayDate) => {
  return today.valueOf() === currentDayDate.valueOf()
}

/**
* Get the holiday or payday emoji if the `currentDay` is a holiday or a payday.
*
* @param {string[]} paydays
* @param {moment} currentDay
* @return {JSX} An emoji if available, null otherwise.
*/
const getEmojiFromDayForCalendar = (paydays, currentDay) => {
  const currentDayDate = currentDay.toDate()
  const holiday = hd.isHoliday(currentDayDate)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  currentDayDate.setHours(0, 0, 0, 0)

  if (holiday) {
    return (
      <Tooltip content={holiday.name}>
        <span role="img" aria-label="Gift">🎁</span>
      </Tooltip>
    )
  }

  if (isPayday(paydays, currentDay)) { return <span role="img" aria-label="Money">💸</span> }

  if (isToday(today, currentDayDate)) {
    return (
      <span role="img" aria-label="Today">👇</span>
    )
  }

  return null
}

const getEmojiFromDayForTimesheet = (paydays, currentDay) => {
  const currentDayDate = currentDay.toDate()
  const holiday = hd.isHoliday(currentDayDate)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  currentDayDate.setHours(0, 0, 0, 0)

  const dayEmoji = []
  if (holiday) {
    dayEmoji.push(
      <Tooltip content={holiday.name} key={currentDayDate.toLocaleDateString('en-US')}>
        <span role="img" aria-label="Gift">🎁</span>
      </Tooltip>
    )
  }

  if (isPayday(paydays, currentDay)) {
    dayEmoji.push(
      <Tooltip key="payday" content="PayDay">
        <span role="img" aria-label="Payday">💸</span>
      </Tooltip>
    )
  }

  if (isToday(today, currentDayDate)) {
    dayEmoji.push(
      <Tooltip key="today" content="Today">
        <span role="img" aria-label="Today">👇</span>
      </Tooltip>
    )
  }

  return (
    <div>{dayEmoji}</div>
  )

}

export default {
  // Constants
  DAYS,
  LONG_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  SHORT_DAY_AND_MONTH_FORMAT,
  MIN_ISO_WEEK_NUMBER,

  // Functions
  dayOfWeekAsString,
  getWeekRange,
  getIsoWeeksInYear,
  getEmojiFromDayForCalendar,
  getEmojiFromDayForTimesheet,
  isToday,
  isPayday,
}
