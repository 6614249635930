import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, ButtonGroup } from '@blueprintjs/core'
import moment from 'moment'

import * as actions from '../../../store/actions'

class WeekSelect extends Component {

  handlerNextWeek = () => {
    const currentDate = moment().isoWeekYear(this.props.currentYear).isoWeek(this.props.currentWeek)
    currentDate.add(1, 'weeks')

    this.props.goToWeek(currentDate.isoWeekYear(), currentDate.isoWeek())
  }

  handlerPreviousWeek = () => {
    const currentDate = moment().isoWeekYear(this.props.currentYear).isoWeek(this.props.currentWeek)
    currentDate.subtract(1, 'weeks')

    this.props.goToWeek(currentDate.isoWeekYear(), currentDate.isoWeek())
  }

  render() {
    const { currentWeek } = this.props

    return (
      <ButtonGroup minimal={false} large={false} >
        <Button icon="caret-left" onClick={this.handlerPreviousWeek}></Button>
        <Button>Week #{currentWeek}</Button>
        <Button icon="caret-right" onClick={this.handlerNextWeek}></Button>
      </ButtonGroup>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentYear: state.timesheet.currentYear,
    currentWeek: state.timesheet.currentWeek
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    goToWeek: (year, week) => dispatch(actions.goToWeek({ year: year, week: week })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WeekSelect)