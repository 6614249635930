import React from 'react'
import PropTypes from 'prop-types'

import './Avatar.css'

const propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  src: PropTypes.string
}

const defaultProps = {
  size: 100
}

const Avatar = (props) => {

  const style = {
    ...props.style,
    width: `${props.size}px`,
    height: `${props.size}px`
  }

  return (
    <div className="avatar" style={style}>
      <img src={props.src} alt="Profil" />
    </div>
  )
}

Avatar.propTypes = propTypes
Avatar.defaultProps = defaultProps

export default Avatar