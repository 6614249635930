import React, { Component } from 'react'
import { Button, Intent, Tooltip } from '@blueprintjs/core'

class TimesheetSend extends Component {

  render() {
    const { timesheet, isCompletedTimesheet, onApprove } = this.props

    const isSend = timesheet && timesheet.status === 'sent'
    const isApproved = timesheet && timesheet.status === 'approved'
    const isManager = onApprove

    let buttonSection

    if (isManager) {
      if (isApproved) {
        buttonSection =
          <Button
            intent={Intent.PRIMARY}
            onClick={this.props.onUnApprove}
            text="UnApprove"
          />
      }
      else {
        const button =
          <Button
            disabled={!isCompletedTimesheet}
            intent={Intent.PRIMARY}
            onClick={this.props.onApprove}
            text="Approve"
          />
        buttonSection = isCompletedTimesheet ? button :
          <Tooltip content={'You cannot approve an uncompleted timesheet'}>
            {button}
          </Tooltip>
      }
    }
    else {
      if (isApproved) {
        buttonSection =
          <Button
            disabled
            intent={Intent.DANGER}
            text="Approved"
          />
      }
      else if (isSend) {
        buttonSection =
          <Button
            intent={Intent.DANGER}
            onClick={this.props.onUnSend}
            text="Unsend"
          />
      }
      else {
        const button =
          <Button
            disabled={!isCompletedTimesheet}
            intent={Intent.PRIMARY}
            onClick={this.props.onSend}
            text="Send"
          />
        buttonSection = isCompletedTimesheet ? button :
          <Tooltip content={'You cannot send an uncompleted timesheet'}>
            {button}
          </Tooltip>
      }
    }
    return buttonSection
  }
}

export default TimesheetSend