import React, { Component } from 'react'

import WeekSelect from './WeekSelect/WeekSelect'
import TaskSelect from './TaskSelect/TaskSelect'
import TimesheetSend from './TimesheetSend/TimesheetSend'

import './TimesheetHeader.css'

class TimesheetHeader extends Component {

  render() {
    const { timesheet, tasks, onAddTimelog, onSend, onUnSend, onApprove, onUnApprove, isCompletedTimesheet } = this.props

    const isSend = timesheet && timesheet.status === 'sent'
    const isApproved = timesheet && timesheet.status === 'approved'
    const isDisabled = isSend || isApproved

    return (
      <div className="timesheet-controller-header">
        <div className="timesheet-controllers">
          <div className="weektask-controllers">
            <WeekSelect />
          </div>
          <div className="weektask-controllers">
            <TaskSelect
              tasks={tasks.filter((t) => t.active)}
              onClick={onAddTimelog}
              disabled={isDisabled}
            />
          </div>
        </div >
        <div className="timesheet-controllers">
          <TimesheetSend
            timesheet={timesheet}
            onSend={onSend}
            onUnSend={onUnSend}
            onApprove={onApprove}
            onUnApprove={onUnApprove}
            isCompletedTimesheet={isCompletedTimesheet}
          />
        </div>
      </div>
    )
  }
}

export default TimesheetHeader