import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as actions from '../../store/actions'

import Title from './Title/Title'
import MenuItem from './MenuItem/MenuItem'
import UserMenu from './UserMenu/UserMenu'

import './Navbar.css'

class Navbar extends Component {

  state = {
    user: this.props.user
  }

  componentWillReceiveProps(newProps) {
    this.setState({ user: newProps.user })
  }

  handleClickHelp = (event) => {
    const win = window.open('https://gitlab.com/UnicornPowered/timesheet#unicornpowered-timesheet', '_blank')
    if (win) {
      win.focus()
    } else {
      this.props.onToast('error', Intent.DANGER, 'Please allow popups for this website!')
    }
  }

  render() {
    let itemWhenLogged = null
    const user = this.state.user

    if (user && !user.isEmpty) {
      itemWhenLogged = (

        <div className="item-menu-container">
          {user.role === 'admin' || user.role === 'manager' || user.role === 'user' ? (<MenuItem icon="bp3-icon-th" to="/timesheet" name="Timesheet" />) : null}
          {user.role === 'admin' || user.role === 'manager' || user.role === 'user' ? (<MenuItem icon="bp3-icon-endorsed" to="/achievements" name="Achievements" />) : null}
          {user.role === 'admin' ? (<MenuItem icon="bp3-icon-settings" to="/admin" name="Admin" />) : null}
          <span className="bp3-navbar-divider"></span>
          <UserMenu user={user} />
          <button className="bp3-button bp3-minimal bp3-icon-help" onClick={this.handleClickHelp}></button>
        </div>
      )
    }

    return (
      <div className="navbar-container">
        <nav className="bp3-navbar">
          <div className="bp3-navbar-group bp3-align-left">
            <Title />
          </div>
          <div >
            <div className="bp3-navbar-group bp3-align-right">
              {itemWhenLogged}
            </div>
          </div>
        </nav>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.firebase.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export { Navbar as MockNavbar }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
