import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import {
  updateObjectInArray
} from '../../Utils'

import './Note.css'

const propTypes = {
  note: PropTypes.string,
  onUpdateTimelog: PropTypes.func,
  onSaveTimelog: PropTypes.func,
  timelog: PropTypes.object,
  day: PropTypes.string,
  subtaskIndex: PropTypes.number
}

class Note extends Component {
  constructor(props) {
    super(props)

    const { subtaskIndex, timelog, day } = props

    const element = subtaskIndex === null ? timelog : timelog.subtasks[subtaskIndex]
    const note = element.comments ? element.comments[day] : ''

    this.state = { note }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        note: this.props.note
      })
    }
  }

  handleOnNoteChange = (event) => {
    const newNote = event.target.value

    if (this.props.subtaskIndex === null) {
      const newTimelog = {
        ...this.props.timelog,
        comments: {
          ...this.props.timelog.comments,
          [this.props.day]: newNote,
        }
      }
      this.props.onUpdateTimelog(newTimelog)
    } else {
      const { subtaskIndex, day } = this.props
      const newSubtasks = updateObjectInArray(
        this.props.timelog.subtasks, { 'index': subtaskIndex, item: { comments: { ...this.props.timelog.subtasks[subtaskIndex].comments, [day]: newNote }}})
      const newTimelog = {
        ...this.props.timelog,
        subtasks: newSubtasks
      }
      this.props.onUpdateTimelog(newTimelog)
    }

    this.setState({ note: newNote })
  }

  render() {
    const { note } = this.state
    const { onSaveTimelog, timelog } = this.props

    return (
      <div className="note">
        <textarea
          style={{ width: '100%', height: '80px' }}
          className="bp3-input"
          value={note}
          onChange={this.handleOnNoteChange}
          onBlur={() => onSaveTimelog(timelog)}
          placeholder={'Add a Note 🦄❤️🦄'}
        />
      </div>
    )
  }
}

Note.propTypes = propTypes

export default Note
