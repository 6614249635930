import React, { Component } from 'react'
import { Button, Classes, Dialog } from '@blueprintjs/core'

import { toTitleCase } from '../../Utils'
import Uploader from '../Uploader/Uploader'

import './AdminEditDialog.css'

const restrictedKeys = ['id', 'type', 'active']

// Returns name and desc first, following with the rest in alphabetical order.
const compareFunction = (a, b) => {
  if (b === 'name') { return 2 }
  if (b === 'desc') { return 1 }
  return 0
}

class AdminEditDialog extends Component {

  // Used props : title, isOpen, handleClose(), handleSave().
  state = {
    task: this.props.task,
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      task: newProps.task,
    })
  }

  onValueChanged = (event, key) => {
    const clonedTask = { ...this.state.task }
    clonedTask[key] = event.target.value

    this.setState({
      task: clonedTask,
    })
  }

  handleSave = () => {
    this.props.handleSave(this.state.task)
    this.props.handleClose()
  }

  updateTaskLogo = (url) => {
    const task = { ...this.state.task }
    task.logoUrl = url
    this.setState({ task: task })
  }

  handleRemoveLogo = () => {
    const task = { ...this.state.task }
    task.logoUrl = null
    this.setState({ task: task })
  }

  renderLogo = (url) => {
    if (url) {
      return (
        <div key={url} className="logo-trash-container">
          <label key={url} className="bp3-label bp3-inline">
            Logo : <br />
            <img src={url} alt="logo" style={{ maxWidth: '120px' }} />
          </label>
          <div>
            <Button icon="trash" onClick={this.handleRemoveLogo} style={{ marginLeft: '10px' }} />
          </div>
        </div>)

    }
  }

  renderDialogInputs = (key, task, index) => {
    if (key === 'logoUrl') {
      return this.renderLogo(task[key])
    }

    return (
      <label key={key} className="bp3-label bp3-inline">
        {toTitleCase(key)} :
        <input className="bp3-input bp3-fill" type="text" dir="auto" value={task[key]} onChange={(e) => this.onValueChanged(e, key)} autoFocus={index === 0} />
      </label>
    )
  }

  render() {
    const task = this.state.task || {}
    const { title, isOpen, handleClose } = this.props

    return (
      <Dialog
        className={Classes.DARK}
        onClose={handleClose}
        title={toTitleCase(title)}
        isOpen={isOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          {
            Object.keys(task)
              .filter((key) => !restrictedKeys.includes(key))
              .sort(compareFunction)
              .map((key, index) => {
                return (
                  this.renderDialogInputs(key, task, index)
                )
              })
          }
          <label className="bp3-label bp3-inline">
            Upload new logo: <br />
            <Uploader task={task} changeLogoUrl={this.updateTaskLogo} />
          </label>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent="danger" onClick={handleClose}>Cancel</Button>
            <Button intent="success" onClick={this.handleSave}>Save</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default AdminEditDialog