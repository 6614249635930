import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getClassNames } from 'dynamic-class-list'

class MenuItem extends Component {
  render() {
    const classes = getClassNames(['bp3-button', 'bp3-minimal', this.props.icon])
    return (
      <Link className={classes} to={this.props.to}>{this.props.name}</Link>
    )
  }
}

export default MenuItem