import * as actionTypes from './actionTypes'

export const goToWeek = (props) => {
  return {
    type: actionTypes.TIMESHEET_GOTO_WEEK,
    year: props.year,
    week: props.week
  }
}

export const selectUser = (props) => {
  return {
    type: actionTypes.TIMESHEET_SELECT_USER,
    user: props.user,
  }
}

export const resetState = () => {
  return {
    type: actionTypes.TIMESHEET_RESET_STATE,
  }
}