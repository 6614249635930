import React, { Component } from 'react'
import Avatar from '../Avatar/Avatar'
import { Button } from '@blueprintjs/core'

class AchievementComment extends Component {

  state = {
    commentText: ''
  }

  handlerUpdateCommentText = (event) => {
    const state = this.state
    state.commentText = event.target.value
    this.setState(state)
  }

  handlerOnSaveComment = () => {
    this.props.onAddComment(this.state.commentText, this.props.id)
    this.setState({
      commentText: ''
    })
  }

  render() {
    const { user } = this.props
    const { commentText } = this.state
    return (
      <div className="row" style={{ marginLeft: '35px', marginBottom: '10px' }}>
        <div className="col-1">
          <Avatar style={{ marginTop: '2px' }} round={true} src={(user) ? user.avatarUrl : ''} size={22} />
        </div>
        <div className="col-9">
          <textarea className="bp3-input bp3-fill" onChange={this.handlerUpdateCommentText} value={commentText} />
        </div>
        <div className="col-2">
          <Button
            onClick={this.handlerOnSaveComment}
            text="Comment"
            disabled={!commentText}
          />
        </div>
      </div>)
  }
}

export default AchievementComment