import React, { Component } from 'react'
import { Button, Position, Tooltip } from '@blueprintjs/core'
import Avatar from '../Avatar/Avatar'
import { getObjectValues } from '../../Utils'

class AchievementLeft extends Component {

  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      likes: props.likes,
      alreadyLike: false,
      alreadyLikeId: '',
    }
    this.updateAlreadyLike()
  }

  componentWillReceiveProps = (newProps) => {
    const state = this.state
    state.likes = newProps.likes
    this.setState(state)
    this.updateAlreadyLike()
  }

  updateAlreadyLike = () => {
    const { user } = this.props
    const { likes } = this.state
    const likesArray = getObjectValues(likes)
    let alreadyLike = false
    let alreadyLikeId = ''
    for (const like of likesArray) {
      if (like.user_display_name === user.displayName) {
        alreadyLike = true
        alreadyLikeId = like.id
      }
    }
    const state = this.state
    state.alreadyLike = alreadyLike
    state.alreadyLikeId = alreadyLikeId
    this.setState(state)
  }

  handlerOnAddLike = () => {
    const { alreadyLike, alreadyLikeId } = this.state
    if (alreadyLike) {
      const state = this.state
      state.alreadyLike = false
      state.alreadyLikeId = ''
      this.setState(state)
      this.props.onDeleteLike(alreadyLikeId, this.props.id)
    }
    else { this.props.onAddLike(this.props.id) }
  }

  render() {
    const { to_avatar } = this.props
    const { likes } = this.state
    const likesArray = getObjectValues(likes)
    const namesList = likesArray.map((item) => {
      return <span key={item.id}> {item.user_display_name} <br /></span>
    })
    return (
      <div>
        <Avatar round={true} src={to_avatar} size={60} style={{ marginBottom: '15px' }} />
        {likesArray.length > 0 ?
          <Tooltip content={namesList} position={Position.BOTTOM}>
            <Button icon="thumbs-up" onClick={() => this.handlerOnAddLike()}>{likesArray.length}</Button>
          </Tooltip>
          :
          <Button icon="thumbs-up" onClick={() => this.handlerOnAddLike()}>0</Button>
        }
      </div>
    )
  }
}

export default AchievementLeft