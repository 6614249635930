import React, { Component } from 'react'
import { Intent } from '@blueprintjs/core'
import { firebaseConnect } from 'react-redux-firebase'

import { toTitleCase } from '../../../Utils'
import Select from '../../../components/Select/Select'
import AdminTitleInfo from '../../../components/AdminTitleInfo/AdminTitleInfo'
import AdminRolesTable from '../../../components/AdminRolesTable/AdminRolesTable'
import PropTypes from 'prop-types'

const propTypes = {
  firebase: PropTypes.object
}

export class Roles extends Component {
  state = {
    roles: {},
    selectedRole: null
  }

  componentDidMount() {
    this.getRoleData()
  }

  /**
  * Get the role data from realtime database
  */
  getRoleData = () => {
    const ref = this.props.firebase.database().ref('/roles')
    ref.once('value')
      .then((data) => {
        this.response = data.val()
        this.setState({ roles: this.response })
      })
  }

  /**
  * Update the value from true to false or vice versa for one role
  */
  updatePermission = (role, permission, value) => {
    this.props.firebase
      .set(`roles/${role}/${permission}/`, value)
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
    this.getRoleData()
  }

  /**
  * Edit (or set) the value of the path
  */
  editPermission = (permission, value, isNew = false) => {
    this.props.firebase
      .set(`roles/role_values/${permission}`, value)
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })

    if (isNew) {
      this.getRoleOptions(this.state.roles).forEach((role) => {
        this.props.firebase
          .set(`roles/${role}/${permission}`, false)
          .catch((error) => {
            this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
          })
      })
    }
    this.getRoleData()
  }

  /**
  * Get value from role_values for a permission
  */
  getRoleValue = (permission) => {
    return this.state.roles.role_values[permission] || ''
  }

  /**
  * Get role options: admin, manager, user
  */
  getRoleOptions = (roles) => {
    if (roles) { //roles is the entire object of roles in props
      roles = Object.keys(roles)
      const index = roles.indexOf('role_values')
      if (index > -1) {
        roles.splice(index, 1)
      }
    }
    return roles
  }

  handleSelectRoleChange = (event) => {
    if (Number(event.target.value) === -1) {
      this.setState({
        selectedRole: null
      })
    }
    else {
      const item = this.getRoleOptions(this.state.roles)[event.target.value]
      if (item) {
        this.setState({
          selectedRole: item,
        })
      }
      else {
        this.props.onToast('error', Intent.DANGER, 'Cannot retrieve the selected item.')
      }
    }
  }

  permissionsForRole = (role, roles) => {
    let permissions = {}
    if (role && roles) {
      if (role === 'admin') { permissions = roles.admin }

      if (role === 'user') { permissions = roles.user }

      if (role === 'manager') { permissions = roles.manager }

      if (permissions) { delete permissions['name'] }
    }
    return permissions
  }

  render() {
    const { roles, selectedRole } = this.state

    const rolesToDisplay = this.getRoleOptions(roles)
    const roleOptions = rolesToDisplay ?
      rolesToDisplay.map((role, index) => {
        return {
          text: toTitleCase(role),
          value: index
        }
      }) : {}

    const roleValue = selectedRole ? rolesToDisplay
      .findIndex((role) => role === selectedRole) : -1

    const rolesToShow = () => {
      let roles = []
      if (rolesToDisplay) {
        roles = rolesToDisplay
        if (selectedRole) {
          const index = roles.indexOf(selectedRole)
          roles = roles.slice(index, index + 1)
        }
      }
      return roles
    }

    return (
      <div>
        <AdminTitleInfo
          title="Roles"
          info="Lists all permissions associated with each role (admin, manager, user)."
        />
        <table width="100%">
          <tbody>
            <tr>
              <td width="30%" style={{ verticalAlign: 'top' }}>
                <Select
                  labelText="Roles"
                  defaultText="All roles"
                  options={roleOptions}
                  value={roleValue}
                  onChange={this.handleSelectRoleChange}
                />
              </td>
              <td width="70%">
              </td>
            </tr>
          </tbody>
        </table>
        {rolesToShow().map((role, index) => {
          return (<AdminRolesTable
            key={index}
            permissions={this.permissionsForRole(role, roles)}
            roleName={role}
            updatePermission={this.updatePermission}
            editPermission={this.editPermission}
            getRoleValue={this.getRoleValue}
          />)
        })}
      </div >
    )
  }
}

Roles.propTypes = propTypes

export default firebaseConnect()(Roles)