import React, { Component } from 'react'
import DayPicker from 'react-day-picker'
import { connect } from 'react-redux'
import moment from 'moment'

import 'react-day-picker/lib/style.css'

import * as actions from '../../store/actions'
import DateUtils from '../../DateUtils'

export class Calendar extends Component {

  // Used props : paydays.
  state = {
    hoverRange: null,
  }

  renderDay = (day) => {
    const currentDay = moment(day)
    const { paydays } = this.props

    const emoji = DateUtils.getEmojiFromDayForCalendar(paydays, currentDay)
    return emoji ? emoji : <div>{currentDay.date()}</div>
  }

  handleDayClick = (day) => {
    const year = moment(day).isoWeekYear()
    const week = moment(day).isoWeek()
    this.props.goToWeek(year, week)
  }

  handleWeekClick = (week, days) => {
    const year = moment(days[0]).isoWeekYear()
    this.props.goToWeek(year, week)
  }

  handleDayEnter = (date) => {
    this.setState({
      hoverRange: DateUtils.getWeekRange(date),
    })
  }

  handleDayLeave = () => {
    this.setState({
      hoverRange: null,
    })
  }

  render() {
    const { hoverRange } = this.state

    const currentDate = moment().isoWeekYear(this.props.currentYear).isoWeek(this.props.currentWeek)
    const selectedRange = DateUtils.getWeekRange(currentDate)

    const modifiers = {
      hoverRange: hoverRange,
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: selectedRange && selectedRange.from,
      selectedRangeEnd: selectedRange && selectedRange.to,
    }

    return (
      <DayPicker
        showOutsideDays
        showWeekNumbers
        footer
        className="Selectable"
        todayButton={'Today'}
        onTodayButtonClick={this.handleDayClick}
        onDayClick={this.handleDayClick}
        onWeekClick={this.handleWeekClick}
        onDayMouseEnter={this.handleDayEnter}
        onDayMouseLeave={this.handleDayLeave}
        numberOfMonths={2}
        firstDayOfWeek={1}
        modifiers={modifiers}
        selectedDays={{
          from: selectedRange.from,
          to: selectedRange.to,
        }}
        renderDay={this.renderDay}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentYear: state.timesheet.currentYear,
    currentWeek: state.timesheet.currentWeek
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    goToWeek: (year, week) => dispatch(actions.goToWeek({ year: year, week: week })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)