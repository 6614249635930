import React, { Component } from 'react'
import DayPicker, { DateUtils as DateUtilsReactDayPicker } from 'react-day-picker'
import { Button, InputGroup, Tooltip, Intent, Position } from '@blueprintjs/core'
import moment from 'moment'

import './SummaryHeader.css'
import 'react-day-picker/lib/style.css'

import DateUtils from '../../DateUtils'

class SummaryHeader extends Component {

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
    this.fromInput = React.createRef()
    this.toInput = React.createRef()

  }

  getInitialState = () => {
    return {
      from: null,
      fromDateInput: '',
      fromDateInputTouched: false,
      to: null,
      toDateInput: '',
      toDateInputTouched: false,
    }
  }

  resetEntireState = () => {
    this.setState(this.getInitialState())
    this.clearReport()
  }

  selectDay = (day) => {
    const range = DateUtilsReactDayPicker.addDayToRange(day, this.state)
    const newState = { ...this.state }
    for (const field in range) {
      newState[field] = range[field]
      newState[`${field}DateInput`] = range[field] ? moment(range[field]).format(DateUtils.SHORT_DATE_FORMAT) : ''
    }
    this.setState(newState)
    if (this.areBothDatesSet(range.from, range.to)) {
      this.showReportForRange(range.from, range.to)
    }
  }

  dateInputHandler = (event) => {
    const inputName = event.target.name
    const value = this.enforceGoodValueFormat(event.target.value)

    const newState = {
      ...this.state,
      [`${inputName}DateInput`]: value,
      [`${inputName}DateInputTouched`]: true
    }

    if (this.doesValueRepresentAValidDate(value)) {
      newState[inputName] = moment(value).toDate()
    } else if (this.hasValueBeenCleared(value)) {
      this.resetInputToItsInitialState(newState, inputName)
      this.clearReport()
    } else if (newState[inputName]) {
      this.clearDateAssociatedToInput(newState, inputName)
      this.clearReport()
    }

    if (this.isBeginDateBiggerThanEndDate(newState.from, newState.to)) {
      this.swapDateInputValues(newState)
      this.switchInputsFocus(inputName)
    }

    this.setState(newState)
    if (this.areBothDatesSet(newState.from, newState.to)) {
      this.showReportForRange(newState.from, newState.to)
    }
  }

  doesValueRepresentAValidDate = (stringDate) => {
    return moment(stringDate, DateUtils.SHORT_DATE_FORMAT, true).isValid()
  }

  hasValueBeenCleared = (value) => {
    return value === ''
  }

  areBothDatesSet = (from, to) => {
    return from && to
  }

  isBeginDateBiggerThanEndDate = (beginDate, endDate) => {
    return this.areBothDatesSet(beginDate, endDate) && beginDate > endDate
  }

  enforceGoodValueFormat = (value) => {
    value = value.replace(/([^0-9-]|(?<!([0-9]{4}|[0-9]{4}-[0-9]{2}))[^0-9])/g, '')
    value = value.replace(/[0-9]{4}(?=[0-9])/, '$&-')
    value = value.replace(/[0-9]{4}-[0-9]{2}(?=[0-9])/, '$&-')
    return value
  }

  swapDateInputValues = (state) => {
    [state.from, state.to, state.fromDateInput, state.toDateInput] = [state.to, state.from, state.toDateInput, state.fromDateInput]
  }

  switchInputsFocus = (currentlyFocusedInputName) => {
    if (currentlyFocusedInputName === 'from') {
      this.toInput.current.focus()
    } else {
      this.fromInput.current.focus()
    }
  }

  resetInputToItsInitialState = (state, inputName) => {
    state[inputName] = null
    state[`${inputName}DateInput`] = ''
    state[`${inputName}DateInputTouched`] = false
  }

  clearDateAssociatedToInput = (state, inputName) => {
    state[inputName] = null
  }

  showReportForRange = (from, to) => {
    this.props.onClick(from, to)
  }

  clearReport = () => {
    this.showReportForRange(null, null)
  }

  renderDay = (day) => {
    const currentDay = moment(day)
    const { paydays } = this.props

    const emoji = DateUtils.getEmojiFromDayForCalendar(paydays, currentDay)
    return emoji ? emoji : <div>{currentDay.date()}</div>
  }

  renderWarning = () => {
    return (<span>Please enter a valid date (YYYY-MM-DD)</span>)
  }

  render() {
    const { from, to } = this.state
    const modifiers = {
      selectedRangeStart: from,
      selectedRangeEnd: to
    }

    let headerContent = <p style={{ paddingLeft: '25px' }}>Please select the first day.</p>

    if (from) {
      if (to) {
        headerContent =
          <p style={{ paddingLeft: '25px' }}>
            {`Selected from ${moment(from).format(DateUtils.SHORT_DATE_FORMAT)} to ${moment(to).format(DateUtils.SHORT_DATE_FORMAT)} `}
            <Button onClick={this.resetEntireState}> Reset</Button >
          </p>
      }
      else {
        headerContent = <p style={{ paddingLeft: '25px' }}>Please select the last day.</p>
      }
    }

    return (
      <div className="range-example">
        {headerContent}
        <DayPicker
          showOutsideDays
          showWeekNumbers
          className="Selectable"
          month={this.state.from}
          numberOfMonths={2}
          firstDayOfWeek={1}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.selectDay}
          renderDay={this.renderDay}
        />
        <div className="date-inputs-div">
          <Tooltip
            isOpen={this.state.fromDateInputTouched && this.state.fromDateInput.length !== 10}
            intent={Intent.DANGER}
            content={this.renderWarning()}
            position={Position.BOTTOM}>
            <InputGroup
              name="from"
              value={this.state.fromDateInput}
              onChange={this.dateInputHandler}
              placeholder={`From ${DateUtils.SHORT_DATE_FORMAT}`}
              maxLength={10}
              inputRef={this.fromInput}
            />
          </Tooltip>
          <Tooltip
            isOpen={this.state.toDateInputTouched && this.state.toDateInput.length !== 10}
            intent={Intent.DANGER}
            content={this.renderWarning()}
            position={Position.BOTTOM}>
            <InputGroup
              name="to"
              value={this.state.toDateInput}
              onChange={this.dateInputHandler}
              placeholder={`To ${DateUtils.SHORT_DATE_FORMAT}`}
              maxLength={10}
              inputRef={this.toInput}
            />
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default SummaryHeader