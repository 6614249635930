import React, { Component } from 'react'

class Select extends Component {
  render() {
    const { labelText, defaultText, options, value, onChange, disabled } = this.props

    let content =
      <div className="bp3-select">
        <select
          value={value}
          onChange={onChange}
          disabled={disabled}>
          {
            defaultText ? <option value={-1}>{defaultText}</option> : null
          }
          {
            options ? options.map((option) => {
              return (
                <option key={option.value} value={option.value}>{option.text}</option>
              )
            }) : null
          }
        </select>
      </div>

    if (labelText) {
      content =
        <label className="bp3-label bp3-inline" style={{ display: 'inline-grid', width: '100%' }}>
          {labelText}
          {content}
        </label>
    }

    return content
  }
}

export default Select