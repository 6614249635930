import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Intent } from '@blueprintjs/core'

import * as actions from '../../../store/actions'
import { getObjectValues } from '../../../Utils'
import AdminTitleInfo from '../../../components/AdminTitleInfo/AdminTitleInfo'
import AdminTasksTable from '../../../components/AdminTasksTable/AdminTasksTable'

class DefaultTasks extends Component {

  updateDefaultTasks = (defaultTask) => {
    this.props.firebase
      .set(`tasks/defaults/${defaultTask.id}`, defaultTask)
      .then(() => { })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  render() {
    return (
      <div>
        <AdminTitleInfo
          title="Default Tasks"
          info="Lists of all default tasks in the system. They are displayed in timesheet of all users."
        />
        <AdminTasksTable
          tasks={this.props.defaultTasks}
          updateTask={this.updateDefaultTasks}
          taskType="default"
          taskDisplayName="default task"
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  firebaseConnect([
    'tasks/defaults',
  ]),
  connect((state, props) => ({
    defaultTasks: state.firebase.data.tasks ? getObjectValues(state.firebase.data.tasks.defaults) : [],
  })),
  connect(mapDispatchToProps)
)(DefaultTasks)