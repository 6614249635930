import React, { Component } from 'react'

import './BgDynamic.css'

class BgDynamic extends Component {
  render() {
    // TODO: add possibility to receive a list of images to render random
    const style = { backgroundImage: 'url(\'images/bg.jpg\')', backgroundSize: 'cover' }
    return (
      <div style={style} className="bg-container">
        {this.props.children}
      </div>
    )
  }
}

export default BgDynamic