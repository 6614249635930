import React, { Component } from 'react'
import { BarChart, XAxis, YAxis, Bar, Tooltip, Legend } from 'recharts'

import Spinner from '../Spinner/Spinner'
import EmptyData from '../EmptyData/EmptyData'

import './SummaryGraph.css'

class SummaryGraph extends Component {

  state = {
    results: this.props.results,
    loading: this.props.loading
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      results: newProps.results,
      loading: newProps.loading
    })
  }

  render() {
    const { results, loading } = this.state

    let content = <EmptyData
      title="No data"
      description="Add more date"
    />

    if (loading) {
      content = <Spinner />
    }
    else if (results && results.length > 0) {
      const formattedData = []

      results.filter((res) => !res.is_subtask).forEach((res) => {
        const existingData = formattedData.find((d) => d.task_id === res.task_id)

        if (existingData) {
          existingData[res.user_display_name] = res.sum
        }
        else {
          formattedData.push({
            task_id: res.task_id,
            task_name: res.task_name,
            [res.user_display_name]: res.sum,
          })
        }
      })

      const uniqueUserDisplayNames =
        results
          .map((res) => res.user_display_name) // Get names
          .filter((v, i, a) => a.indexOf(v) === i) // Remove duplicates

      content =
        <BarChart width={800} height={300} data={formattedData}>
          <XAxis dataKey="task_name" tick={{ fill: 'white' }} stroke="#ffffff" />
          <YAxis tick={{ fill: 'white' }} stroke="#ffffff" />
          <Tooltip wrapperStyle={{ width: 200, backgroundColor: '#25303A', color: 'black' }} />
          <Legend />
          {
            uniqueUserDisplayNames.map((displayName, i) =>
              <Bar key={i} type="monotone" dataKey={displayName} stackId="a" barSize={30} fill={'#' + Math.random().toString(16).substr(-6)} />
            )
          }
        </BarChart>
    }

    return (
      <div className="summary-graph">
        <div className="bp3-card bp3-elevation-2">
          {content}
        </div>
      </div>
    )
  }
}

export default SummaryGraph
