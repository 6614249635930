import React, { Component } from 'react'
import Select from 'react-select'
import { toTitleCase } from '../../../Utils'
import { groupBy } from 'lodash'

import './TaskSelect.css'

class TaskSelect extends Component {

  changeHandler = (value) => {
    const selectedTask = this.props.tasks[value]
    this.props.onClick({
      task_id: selectedTask.id,
      task_type: selectedTask.type,
    })
    this.setState({ searchQuery: '' })
  };

  formatTasks = () => {
    return this.props.tasks ?
      this.props.tasks.map((task, index) => {
        return {
          label: task.name,
          value: index,
          type: toTitleCase(task.type),
          name: toTitleCase(task.type) + '/' + task.name
        }
      }) :
      []
  }

  FormatGroupedOption = (formattedTask) => {
    const uniqueType = [...new Set(formattedTask.map((task) => task.type))]
    const separateValue = groupBy(formattedTask, 'type')

    const newArray = uniqueType.map((type, i) => {
      return {
        label: uniqueType[i],
        options: separateValue[uniqueType[i]],
      }
    })
    return newArray
  }

  themeSelect = (theme) => {
    const themeSelect = {
      ...theme,
      colors: {
        ...theme.colors,
        neutral0: '#394b59',
        neutral90: '#fff',
        primary: '#222',
        primary25: '#30404D',
        primary50: '#30404D',
        primary75: '#30404D'
      }
    }
    return themeSelect
  }

  customSelect = () => {
    return {
      control: (base) => ({
        ...base,
        height: 31,
        minHeight: 31,
        borderColor: '#32424F',
        boxShadow: '0 0 0 1px rgba(16, 22, 26, 0.4)',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#32424F',
        },
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          top: '50%',
          color: 'white'
        }
      },
      dropdownIndicator: (defaultStyles) => ({
        ...defaultStyles,
        padding: '5px',
      }),
      indicatorSeparator: (base) => ({
        ...base,
        height: '40%'
      }),
      menuList: (base) => ({
        ...base,
        '::-webkit-scrollbar': {
          width: '9px'
        },
        '::-webkit-scrollbar-track': {
          background: '#888'
        },
        '::-webkit-scrollbar-thumb': {
          background: '#2a3842'
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#232f38'
        }
      }),
      menu: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: '1px'
      }),
      option: (provided) => ({
        ...provided,
        color: 'white',
        cursor: 'pointer',
      }),
      input: () => ({
        color: 'white',
      })
    }
  }

  render() {
    const formattedTasks = this.formatTasks()

    formattedTasks.sort((a, b) => (a.label > b.label) ? 1 : -1)

    const groupedOptions = this.FormatGroupedOption(formattedTasks)

    return (<Select
      className="select-input-task"
      classNamePrefix="select"
      placeholder="Select a task or search one …"
      isClearable={true}
      styles={this.customSelect()}
      value={null}
      options={groupedOptions}
      onChange={(formattedTask) => this.changeHandler(formattedTask.value)}
      theme={this.themeSelect}
    />)
  }
}

export default TaskSelect
