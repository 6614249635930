import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

import * as actions from '../../../store/actions'
import {
  getObjectValues,
  getTasks,
  getSubtasks,
  sumOfSubtasksForDay
} from '../../../Utils'
import DateUtils from '../../../DateUtils'
import Calendar from '../../../components/Calendar/Calendar'
import TimesheetHeader from '../../../components/TimesheetHeader/TimesheetHeader'
import TimesheetTable from '../../../components/TimesheetTable/TimesheetTable'
import TimesheetComment from '../../../components/TimesheetComment/TimesheetComment'

import './Time.css'

const CONFIG_HOURS_BY_WEEK_ID = 0
const CONFIG_PAYDAY_ID = 4
const CONFIG_MINIMAL_BANK_TIME_ID = 8
const CONFIG_MAXIMAL_PERSONAL_TIME_ID = 1

export class Time extends Component {
  /**
   * Get the latest data of the timesheet user, particularly bankTime.
   * Note : This is necessary because the timesheetUser is in Redux and thus is not up-to-date
   */
  getTimesheetUser(props) {
    const timesheetUser = props.users.find((u) => u.uid === props.timesheetUser.uid)
    return timesheetUser ? timesheetUser : props.timesheetUser
  }

  state = {
    configs: this.props.configs,
    currentYear: this.props.currentYear,
    currentWeek: this.props.currentWeek,
    user: this.props.user,
    timesheetUser: (this.props.timesheetUser) ? this.getTimesheetUser(this.props) : this.props.user,
    tasks: this.props.tasks,
    subtasks: this.props.subtasks,
    timelogs: this.props.timelogs,
    timesheet: this.props.timesheet,
    comments: this.props.comments,
    paydays: this.generatePaydays(this.props.configs.length > 0 && this.props.configs.find((c) => c.id === CONFIG_PAYDAY_ID).value),
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      configs: newProps.configs,
      currentYear: newProps.currentYear,
      currentWeek: newProps.currentWeek,
      user: newProps.user,
      timesheetUser: (newProps.timesheetUser) ? this.getTimesheetUser(newProps) : newProps.user,
      tasks: newProps.tasks,
      subtasks: newProps.subtasks,
      timelogs: newProps.timelogs,
      timesheet: newProps.timesheet,
      comments: newProps.comments,
      paydays: this.generatePaydays(newProps.configs.length > 0 && newProps.configs.find((c) => c.id === CONFIG_PAYDAY_ID).value),
    })
  }

  generatePaydays(initialPayday) {
    const payDays = []
    if (initialPayday) {
      const payday = moment(initialPayday).subtract(52, 'weeks') // Go 1 year in the past

      // Go 5 year in the future
      for (let i = 0; i < 26 * 5; i++) {
        payDays.push(payday.format(DateUtils.SHORT_DATE_FORMAT))
        payday.add(2, 'weeks')
      }
    }
    return payDays
  }

  handlerAddTimelog = (timelog) => {
    const existingTask = this.state.timelogs.find((t) => t.task_type === timelog.task_type && t.task_id === timelog.task_id)

    if (existingTask) {
      this.props.onToast('info-sign', Intent.PRIMARY, 'The current task already exist.')
    }
    else {
      const timelogSave = {
        ...timelog,
        comments: {},
        subtasks: [],
        year: this.state.currentYear,
        week: this.state.currentWeek,
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: ''
      }
      this.props.firebase
        .push(`timelogs/${this.state.currentYear}/${this.state.currentWeek}/${this.state.timesheetUser.uid}/`, timelogSave)
        .then((result) => {
          const key = result.key
          this.props.firebase
            .set(`timelogs/${this.state.currentYear}/${this.state.currentWeek}/${this.state.timesheetUser.uid}/${key}/id`, key)
            .catch((error) => {
              this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
            })
        })
        .catch((error) => {
          this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
        })
    }
  };

  handlerAddSubtask = async (timelog, subtaskId) => {
    const subtask = get(this.state.subtasks, [timelog.task_type, timelog.task_id, subtaskId], {})

    const newTimelog = cloneDeep(timelog)

    if (!newTimelog.hasOwnProperty('subtasks')) {
      newTimelog.subtasks = []
    }
    newTimelog.subtasks.push({
      comments: {},
      year: this.state.currentYear,
      week: this.state.currentWeek,
      name: subtask.name,
      task_id: newTimelog.task_id,
      subtask_id: subtaskId,
      monday: '',
      tuesday: '',
      wednesday: '',
      thursday: '',
      friday: '',
      saturday: '',
      sunday: ''
    })

    this.handlerSaveTimelog(newTimelog)
  }

  handlerNewSubtask = async (timelog, newSubtaskName) => {
    const newSubtask = {
      name: newSubtaskName,
      type: timelog.task_type,
      task_id: timelog.task_id,
    }

    try {
      const result = await this.props.firebase.push(`subtasks/${this.state.timesheetUser.uid}/${timelog.task_type}/${timelog.task_id}/`, newSubtask)
      const key = result.key
      await this.props.firebase.set(`subtasks/${this.state.timesheetUser.uid}/${timelog.task_type}/${timelog.task_id}/${key}/id`, key)
      await this.handlerAddSubtask(timelog, key)
    } catch (error) {
      this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
    }
  }

  handlerSaveTimelog = (timelog) => {
    this.props.firebase
      .set(`timelogs/${this.state.currentYear}/${this.state.currentWeek}/${this.state.timesheetUser.uid}/${timelog.id}`, timelog)
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  updateUserBankAndPersoTime = (bankTimeDiff, personalTimeDiff) => {
    this.props.firebase
      .ref('users/' + this.state.timesheetUser.uid + '/bankTime')
      .transaction((bankTime) => bankTime += bankTimeDiff)
      .then(() => {
        this.props.firebase
          .ref('users/' + this.state.timesheetUser.uid + '/personalTime')
          .transaction((personalTime) => personalTime -= personalTimeDiff)
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })

  }

  handlerDeleteTimelog = (timelog) => {
    this.props.firebase
      .ref('timelogs/' + this.state.currentYear + '/' + this.state.currentWeek + '/' + this.state.timesheetUser.uid + '/' + timelog.id)
      .remove()
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerAddComment = (comment) => {
    const { user, timesheetUser, currentYear, currentWeek } = this.state

    this.props.firebase
      .push('timesheets_comments/' + currentYear + '/' + currentWeek + '/' + timesheetUser.uid, {
        user_id: user.uid,
        user_display_name: user.displayName,
        user_avatar: user.avatarUrl,
        user_slack_channel_id: user.slack ? user.slack.channel_id : '',
        timesheet_user_slack_channel_id: timesheetUser.slack ? timesheetUser.slack.channel_id : '',
        date: Date.now(),
        text: comment
      })
      .then((result) => {
        const key = result.key
        this.props.firebase
          .set('timesheets_comments/' + currentYear + '/' + currentWeek + '/' + timesheetUser.uid + '/' + key + '/id', key)
          .then(() => {
            this.props.onToast('info-sign', Intent.SUCCESS, 'Comment added!')
          })
          .catch((error) => {
            this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
          })
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerDeleteComment = (comment) => {
    this.props.firebase
      .ref('timesheets_comments/' + this.state.currentYear + '/' + this.state.currentWeek + '/' + this.state.timesheetUser.uid + '/' + comment.id)
      .remove()
      .then(() => {
        this.props.onToast('info-sign', Intent.SUCCESS, 'Comment removed!')
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerSendTimesheet = () => {
    this.saveTimesheet('sent')
  }

  handlerUnSendTimesheet = () => {
    this.saveTimesheet('unsent')
  }

  handlerApproveTimesheet = () => {
    this.saveTimesheet('approved')
  }

  handlerUnApproveTimesheet = () => {
    this.saveTimesheet('unsent')
  }

  /**
   * Try to update the user bank time (if the timesheet is valid).
   * @returns Whether the timesheet is valid or not.
   */
  tryUpdateUserBankAndPersoTime = (status) => {
    const timesheetStatus = this.state.timesheet ? this.state.timesheet.status : 'unsent'
    if (status !== 'approved' || timesheetStatus === 'unsent') {
      // Accepted status : 'sent', 'unsent' or 'approved' when the timesheet status is 'unsent'
      // Bank time update
      const minimalBankTimeConfig = this.state.configs.find((c) => c.id === CONFIG_MINIMAL_BANK_TIME_ID)
      const minimalBankTime = minimalBankTimeConfig ? Number(minimalBankTimeConfig.value) : 0

      const currentBankTime = status === 'unsent' ? this.getBankTime() : this.getBankTime() * -1
      const currentOvertime = status === 'unsent' ? this.getOvertime(currentBankTime) * -1 : this.getOvertime(currentBankTime)
      const currentTotalBankTime = currentBankTime + currentOvertime

      if (this.state.timesheetUser.bankTime + currentTotalBankTime < minimalBankTime) {
        this.props.onToast('error', Intent.DANGER, 'Bank time must be always greater or equal to the minimal bank time allowed (' + minimalBankTime + ').')
        return false
      }

      // Personal time update
      const maximalPersonalTimeConfig = this.state.configs.find((c) => c.id === CONFIG_MAXIMAL_PERSONAL_TIME_ID)
      const maximalPersonalTime = maximalPersonalTimeConfig ? Number(maximalPersonalTimeConfig.value) : 0

      const currentUsedPersonalTime = status === 'unsent' ? this.getPersonalTime() * -1 : this.getPersonalTime()
      const totalRemainingPersonalTime = this.state.timesheetUser.personalTime - currentUsedPersonalTime

      if (totalRemainingPersonalTime < 0) {
        this.props.onToast('error', Intent.DANGER, 'You cannot use more personal hours than the maximum allowed (' + maximalPersonalTime + ').')
        return false
      }
      this.updateUserBankAndPersoTime(currentTotalBankTime, currentUsedPersonalTime)
    }
    return true
  }

  /**
   * Save the current timesheet to the RTDB (if the timesheet is valid).
   */
  saveTimesheet = (status) => {
    if (this.tryUpdateUserBankAndPersoTime(status)) {
      // Update timesheet
      this.props.firebase
        .set('timesheets/' + this.state.currentYear + '/' + this.state.currentWeek + '/' + this.state.timesheetUser.uid, {
          status: status,
          date: Date.now(),
        })
        .then((comment) => {
          const { user, timesheetUser, currentYear, currentWeek } = this.state
          this.props.firebase
            .push('timesheets_comments/' + currentYear + '/' + currentWeek + '/' + timesheetUser.uid, {
              user_slack_channel_id: user.slack ? user.slack.channel_id : '',
              timesheet_user_slack_channel_id: timesheetUser.slack ? timesheetUser.slack.channel_id : '',
              date: Date.now(),
              text: 'The timesheet was ' + status + ' on ' + moment(this.date).format('LL') + ' at ' + moment(this.date).format('LT') + '.',
            })
            .then((result) => {
              const key = result.key
              this.props.firebase
                .set('timesheets_comments/' + currentYear + '/' + currentWeek + '/' + timesheetUser.uid + '/' + key + '/id', key)
                .catch((error) => {
                  this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
                })
            })
            .catch((error) => {
              this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
            })
        })
        .then((result) => {
          this.props.onToast('info-sign', Intent.SUCCESS, 'Timesheet updated: ' + status)
        })
        .catch((error) => {
          this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
        })
    }
  }

  getBankTime = () => {
    const bankTask = this.state.timelogs.find((timelog) => this.getTaskName(timelog) === 'Bank')
    return bankTask ? this.getTotalTimeOfWeek([bankTask]) : 0
  }

  getOvertime = (bankTime) => {
    const hoursByWeekConfig = this.state.configs.find((c) => c.id === CONFIG_HOURS_BY_WEEK_ID)
    const hoursByWeek = hoursByWeekConfig ? Number(hoursByWeekConfig.value) : 0
    const totalTime = this.getTotalTimeOfWeek()
    return Math.max(totalTime - Math.abs(bankTime) - hoursByWeek, 0)
  }

  getPersonalTime = () => {
    const personalTask = this.state.timelogs.find((timelog) => this.getTaskName(timelog) === 'Sick/Personal')
    return personalTask ? this.getTotalTimeOfWeek([personalTask]) : 0
  }

  getTotalTimeOfDay = (day, timelogs = this.state.timelogs) => {
    return timelogs.reduce((acc, timelog) => {
      if (timelog.subtasks !== undefined && timelog.subtasks.length > 0) {
        return acc + sumOfSubtasksForDay(timelog, day)
      }
      return acc + Number(timelog[day])
    }, 0)
  }

  getTotalTimeOfWeek = (timelogs = this.state.timelogs) => {
    return DateUtils.DAYS.reduce((acc, day) => acc + this.getTotalTimeOfDay(day, timelogs), 0)
  }

  getTaskName = (timelog) => this.state.tasks.find((t) => t.id === timelog.task_id).name;

  render() {
    const { tasks, subtasks, timesheet, user, timesheetUser, timelogs, configs, comments, currentYear, currentWeek, paydays } = this.state

    const approve = (user.uid !== timesheetUser.uid) ? this.handlerApproveTimesheet : null
    const unApprove = (user.uid !== timesheetUser.uid) ? this.handlerUnApproveTimesheet : null
    const hoursByWeekConfig = configs.find((c) => c.id === CONFIG_HOURS_BY_WEEK_ID)
    const hoursByWeek = hoursByWeekConfig ? Number(hoursByWeekConfig.value) : 0

    return (
      <div className="timesheet-container">
        <div className="timesheet-leftsection">
          <Calendar
            paydays={paydays}
          />
        </div>
        <div className="timesheet-mainsection">
          <TimesheetHeader
            tasks={tasks}
            onAddTimelog={this.handlerAddTimelog}
            timesheet={timesheet}
            onSend={this.handlerSendTimesheet}
            onUnSend={this.handlerUnSendTimesheet}
            onApprove={approve}
            onUnApprove={unApprove}
            isCompletedTimesheet={this.getTotalTimeOfWeek() >= hoursByWeek}
          />

          <TimesheetTable
            currentYear={currentYear}
            currentWeek={currentWeek}
            user={timesheetUser}
            tasks={tasks}
            subtasks={subtasks}
            timelogs={timelogs}
            timesheet={timesheet}
            onSaveTimelog={this.handlerSaveTimelog}
            onDeleteTimelog={this.handlerDeleteTimelog}
            onNewSubtask={this.handlerNewSubtask}
            onAddSubtask={this.handlerAddSubtask}
            hoursByWeek={hoursByWeek}
            paydays={paydays}
          />

          <TimesheetComment
            user={user}
            comments={comments}
            onAddComment={this.handlerAddComment}
            onDeleteComment={this.handlerDeleteComment}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentYear: state.timesheet.currentYear,
    currentWeek: state.timesheet.currentWeek,
    user: state.firebase.profile,
    timesheetUser: state.timesheet.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps),
  firebaseConnect((props) => [
    'users',
    'configs',
    'tasks',
    {
      path: 'subtasks/' + (props.timesheetUser ? props.timesheetUser.uid : props.user.uid),
      storeAs: 'subtasks',
    },
    {
      path: 'timelogs/' + props.currentYear + '/' + props.currentWeek + '/' + (props.timesheetUser ? props.timesheetUser.uid : props.user.uid),
      storeAs: 'timelogs',
    },
    {
      path: 'timesheets/' + props.currentYear + '/' + props.currentWeek + '/' + (props.timesheetUser ? props.timesheetUser.uid : props.user.uid),
      storeAs: 'timesheet',
    },
    {
      path: 'timesheets_comments/' + props.currentYear + '/' + props.currentWeek + '/' + (props.timesheetUser ? props.timesheetUser.uid : props.user.uid),
      storeAs: 'timesheet_comments',
    },
  ]),
  connect((state, props) => ({
    users: getObjectValues(state.firebase.data.users),
    configs: getObjectValues(state.firebase.data.configs),
    tasks: getTasks(state.firebase.data.tasks),
    subtasks: getSubtasks(state.firebase.data.subtasks),
    timelogs: getObjectValues(state.firebase.data.timelogs),
    timesheet: state.firebase.data.timesheet,
    comments: state.firebase.data.timesheet_comments,
  }))
)(Time)
