import React, { Component } from 'react'

class EmptyData extends Component {

  render() {
    return (
      <div className="bp3-non-ideal-state">
        <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
          <span className="bp3-icon bp3-icon-info-sign"></span>
        </div>
        <h4 className="bp3-heading bp3-non-ideal-state-title">{this.props.title}</h4>
        <div className="bp3-non-ideal-state-description">
          {this.props.description}
        </div>
      </div>
    )
  }
}

export default EmptyData