import * as actionsTypes from '../actions/actionTypes'
import { List } from 'immutable'

const initialState = {
  toastQueue: List([]),
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state
    case actionsTypes.CLEAR_TOASTS:
      return {
        ...state,
        toastQueue: List([]),
      }
    case actionsTypes.ENQUEUE_TOAST: {
      const toastQueue = state.toastQueue.push(action.toast)
      return {
        ...state,
        toastQueue,
      }
    }
  }
}

export default reducer