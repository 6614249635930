import React, { Component } from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import Navbar from '../../components/Navbar/Navbar'
import Login from '../Login/Login'
import Timesheet from '../Timesheet/Timesheet'
import Achievements from '../Achievements/Achievements'
import Admin from '../Admin/Admin'
import WrapToaster from '../WrapToaster/WrapToaster'
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import * as actions from '../../store/actions'

import './App.css'

export class App extends Component {

  constructor(props) {
    super(props)
    this.props.resetState()
  }

  state = {
    email: '',
    password: '',
    user: this.props.user
  }

  componentWillReceiveProps(newProps) {
    this.setState({ user: newProps.user })
  }

  render() {
    const authed = (this.state.user && !this.state.user.isEmpty)
    const userLoaded = (this.state.user && this.state.user.isLoaded)
    return (
      <WrapToaster>
        <div className="bp3-dark">
          <Navbar />
          <Switch>
            <Route path="/" exact component={Login} />
            <PrivateRoute authed={authed} userLoaded={userLoaded} path="/achievements" component={Achievements} />
            <PrivateRoute authed={authed} userLoaded={userLoaded} path="/timesheet" component={Timesheet} />
            <PrivateRoute authed={authed} userLoaded={userLoaded} path="/admin" component={Admin} />
          </Switch>
        </div>
      </WrapToaster>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.firebase.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(actions.resetState()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
