import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import { reactReduxFirebase, firebaseReducer, getFirebase } from 'react-redux-firebase'
import { Firebase } from '../firebase/firebase'

import toastReducer from './reducers/toast'
import timesheetReducer from './reducers/timesheet'
// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users', // where profiles are stored in database
  profileParamsToPopulate: [
    { child: 'role', root: 'roles' }, // populates user's role with matching role object from roles
  ],
  profileFactory: (user, profile) => { // how profiles are stored in database
    return ({
      email: user.email || user.providerData[0].email,
      avatarUrl: user.avatarUrl || user.providerData[0].photoURL,
      displayName: user.displayName || user.providerData[0].displayName,
      uid: user.uid,
      lastLoginAt: Date.now(),
      providerData: user.providerData,
    })
  }
}

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['ui']
}

const rootReducer = combineReducers({
  ui: toastReducer,
  timesheet: timesheetReducer,
  firebase: firebaseReducer
})

const persistReducers = persistReducer(persistConfig, rootReducer)
const initialState = {}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(
  persistReducers,
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument(getFirebase)
    ),
    reactReduxFirebase(Firebase.getInstance().getFirebase(), rrfConfig)
  )
)

export const persistor = persistStore(store)