// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { populate } from 'react-redux-firebase'

const validateAuthorization = (location, user) => {
  let ret = false
  if (user && user.role) {
    const role = user.role
    const paths = location.split('/')
    let right = ''
    let needDash = false
    for (let i = 0; i < paths.length; i++) {
      if (paths[i].length > 0) {
        right = right + (needDash ? '-' : '') + paths[i]
        needDash = true
      }
    }
    ret = role[right]
  }
  return ret
}

const PrivateRoute = ({ component: Component, authed, userLoaded, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(props) => ((authed && validateAuthorization(props.location.pathname, rest.populatedProfile)) || !userLoaded) ? (<Component {...props} />) : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
      }
    />
  )
}

export const profilePopulates = [{ child: 'role', root: 'roles' }]

export default connect(
  ({ firebase }) => ({
    profile: firebase.profile,
    populatedProfile: populate(firebase, 'profile', profilePopulates),
  }))(PrivateRoute)
