import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Position, Tooltip } from '@blueprintjs/core'
import { Button, Intent } from '@blueprintjs/core'
import Avatar from '../Avatar/Avatar'
import moment from 'moment'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'

import * as actions from '../../store/actions'
import {
  getObjectValues,
  updateObjectInArray,
  toTitleCase,
  hasSubtasks,
  sumOfSubtasksForDay
} from '../../Utils'
import DateUtils from '../../DateUtils'
import EmptyData from '../EmptyData/EmptyData'
import TimeSheetStatus from '../TimesheetStatus/TimeSheetStatus'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import trim from 'lodash/trim'
import TaskNote from '../TaskNote/TaskNote'
import SubtaskCreationDialog from '../SubtaskCreation/SubtaskCreation.js'

import './TimesheetTable.css'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const propTypes = {
  currentYear: PropTypes.number,
  currentWeek: PropTypes.number,
  user: PropTypes.object,
  tasks: PropTypes.array,
  subtasks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  timelogs: PropTypes.array,
  timesheet: PropTypes.object,
  onSaveTimelog: PropTypes.func,
  onDeleteTimelog: PropTypes.func,
  hoursByWeek: PropTypes.number,
  paydays: PropTypes.array
}

export class TimesheetTable extends Component {

  constructor(props) {
    super(props)
    // Used props : currentYear, currentWeek, timesheet, hoursByWeek, user, paydays, onSaveTimelog(), onDeleteTimelog().
    this.state = {
      timelogs: this.props.timelogs,
      tasks: this.props.tasks,
      subtasks: this.props.subtasks,
      selectedDay: null,
      notesIsOpen: false,
      selectedIndex: 0,
      selectedSubtaskIndex: null,
      subtaskCreationIsOpen: false,
      subtaskCreationSelectedTaskIndex: 0
    }
    this.delayedClick = null
    this.clickedOnce = false
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        timelogs: this.props.timelogs,
        tasks: this.props.tasks,
        subtasks: this.props.subtasks,
      })
    }
  }

  getValidDecimal = (n) => {
    const availableDecimals = ['25', '50', '5', '75']

    if (isNaN(n)) {
      return ''
    }

    const splitN = n.split('.')
    if (splitN.length > 1) {
      if (availableDecimals.includes(splitN[1])) {
        return `${splitN[0]}.${splitN[1]}`
      }
      return `${splitN[0]}.`
    }
    return `${n}`
  }

  getValidNumber = (n) => {
    if (isNaN(n)) {
      return ''
    }

    const splitN = n.split('.')
    if (splitN.length > 1) {
      return `${splitN[0]}.${splitN[1]}`
    }
    return `${splitN[0]}`
  }

  handlerTimeChange = (event) => {
    const hours = this.getValidNumber(event.target.value.trim())
    const index = Number(event.target.name.split('_')[0])
    const day = event.target.name.split('_')[1]

    const timelogs = updateObjectInArray(this.state.timelogs, { 'index': index, item: { [day]: hours } })

    // Update hours worked
    this.setState({ timelogs })
  }

  handlerSubtaskChange = (event) => {
    const hours = this.getValidNumber(event.target.value.trim())
    const index = Number(event.target.name.split('_')[0])
    const subtaskIndex = event.target.name.split('_')[1]
    const day = event.target.name.split('_')[2]

    const newTimelog = cloneDeep(this.state.timelogs)

    newTimelog[index].subtasks[subtaskIndex][day] = hours

    const timelogs = updateObjectInArray(this.state.timelogs, { 'index': index, item: newTimelog[index] })

    // Update hours worked
    this.setState({ timelogs })
  }

  getTotalTimeOfDay = (day, timelogs = this.state.timelogs) => {
    return timelogs.reduce((acc, timelog) => {
      if (hasSubtasks(timelog)) {
        return acc + sumOfSubtasksForDay(timelog, day)
      }
      return acc + Number(timelog[day])
    }, 0)
  }

  getTotalTimeOfWeek = (timelogs = this.state.timelogs) => {
    return DateUtils.DAYS.reduce((acc, day) => acc + this.getTotalTimeOfDay(day, timelogs), 0)
  }

  getTaskTotalTimeOfWeek = (timelog) => timelog ? this.getTotalTimeOfWeek([timelog]) : 0;

  getBankTaskTotalTimeOfWeek = () => this.getTaskTotalTimeOfWeek(this.state.timelogs.find((timelog) => this.getTaskName(timelog) === 'Bank'));

  getPersonalTaskTotalTimeOfWeek = () => this.getTaskTotalTimeOfWeek(this.state.timelogs.find((timelog) => this.getTaskName(timelog) === 'Sick/Personal'));

  getTaskDesc = (timelog) => this.getTaskProp(timelog.task_id, 'desc')
  getTaskName = (timelog) => this.getTaskProp(timelog.task_id, 'name')
  getTaskLogo = (timelog) => this.getTaskProp(timelog.task_id, 'logoUrl')

  getTaskProp = (taskId, prop) => {
    const task = this.state.tasks.find((t) => t.id === taskId)
    return (task && task[prop]) || `No ${prop} provided.`
  }

  getSubtaskName = (timelog, subtask) => this.getSubtaskProp(timelog.task_type, subtask.task_id, subtask.subtask_id, 'name')
  getSubtaskLogo = (timelog, subtask) => this.getSubtaskProp(timelog.task_type, subtask.task_id, subtask.subtask_id, 'logoUrl')

  getSubtaskProp = (taskType, taskId, subtaskId, prop) => {
    const foundProp = get(this.state.subtasks, [taskType, taskId, subtaskId, prop], null)
    if (foundProp === null) {
      return `No ${prop} provided.`
    } else {
      return foundProp
    }
  }

  renderTaskLogoImg = (timelog) => {
    const url = this.getTaskLogo(timelog)
    return url !== 'No logoUrl provided.' ? <img src={url} alt="logo" className="customer-logo" /> : null
  }

  renderSubtaskLogoImg = (timelog, subtask) => {
    const url = this.getSubtaskLogo(timelog, subtask)
    return url !== 'No logoUrl provided.' ? <img src={url} alt="logo" className="customer-logo" /> : null
  }

  getTaskDisplayName = (timelog) => {
    if (timelog == null) {
      return ''
    }
    const taskType = toTitleCase(timelog.task_type)
    const taskName = this.getTaskName(timelog)
    const bankTime =
      taskName === 'Bank' ?
        ' (' + this.props.user.bankTime + ' hours remaining)' :
        ''
    const personalTime =
      taskName === 'Sick/Personal' ?
        ' (' + this.props.user.personalTime + ' hours remaining)' :
        ''
    return taskType + '/' + taskName + bankTime + personalTime
  }

  getSubtaskDisplayName = (timelog, subtask) => {
    if (timelog == null) {
      return ''
    }
    return this.getSubtaskName(timelog, subtask)
  }

  handleTimeLogChange = (timelog, event) => {
    const newEvent = cloneDeep(event)
    newEvent.target.value = this.getValidDecimal(newEvent.target.value)
    this.handlerTimeChange(newEvent)
    const day = newEvent.target.name.split('_')
    const newTimeLogs = cloneDeep(timelog)
    newTimeLogs[day[1]] = newEvent.target.value
    this.props.onSaveTimelog(newTimeLogs)
  }

  saveTimelog = (timelog) => {
    this.props.onSaveTimelog(timelog)
  }

  onAddSubtask = (taskIndex, selectedSubtask) => {
    const timelog = this.state.timelogs[taskIndex]
    if (this.subtaskAlreadyPresent(timelog, selectedSubtask)) {
      this.props.onToast('error', Intent.DANGER, 'This subtask already exist in this timelog')
    } else if (this.hasHours(timelog)) {
      this.props.onToast('error', Intent.DANGER, 'Cannot add subtask when there are hours in the task')
    } else {
      this.props.onAddSubtask(timelog, selectedSubtask.id)
    }
  }

  onNewSubtask = (taskIndex, newSubtaskName) => {
    const timelog = this.state.timelogs[taskIndex]
    if (this.hasHours(timelog)) {
      this.props.onToast('error', Intent.DANGER, 'Cannot add subtask when there are hours in the task')
    } else {
      this.props.onNewSubtask(timelog, newSubtaskName)
    }
  }

  subtaskAlreadyPresent = (timelog, selectedSubtask) => {
    if (!timelog.hasOwnProperty('subtasks')) {
      return false
    }
    const subtask = timelog.subtasks.find((subtask) => subtask.subtask_id === selectedSubtask.id)
    return subtask !== undefined
  }

  hasHours = (timelog) => {
    const workHours = DateUtils.DAYS.map((day) => {
      return timelog[day]
    }).filter((value) => trim(value) !== '' && parseFloat(value) !== 0)
    return workHours.length > 0
  }

  getExistingSubtasks = () => {
    const selectedTimelog = get(this.state.timelogs, [this.state.subtaskCreationSelectedTaskIndex], null)
    if (selectedTimelog === null) {
      return []
    }

    const subtasks = get(this.state.subtasks, [selectedTimelog.task_type, selectedTimelog.task_id], {})
    return getObjectValues(subtasks)
  }

  deleteTimelog = (timelog) => {
    this.props.onDeleteTimelog(timelog)
  }

  deleteSubtask = (timelog, subtaskIndex) => {
    const newTimelog = cloneDeep(timelog)
    newTimelog.subtasks.splice(subtaskIndex, 1)
    this.props.onSaveTimelog(newTimelog)
  }

  onClose = () => {
    this.setState({ notesIsOpen: false }, () => {
      this.setState({ selectedDay: null, selectedIndex: null, selectedSubtaskIndex: null })
    })
  }

  openDayComment = (taskIndex, day) => {
    this.setState({
      selectedDay: day,
      notesIsOpen: true,
      selectedIndex: taskIndex,
      selectedSubtaskIndex: null
    })
  }

  openDaySubtaskComment = (taskIndex, subtaskIndex, day) => {
    this.setState({
      selectedDay: day,
      notesIsOpen: true,
      selectedIndex: taskIndex,
      selectedSubtaskIndex: subtaskIndex
    })
  }

  openNotes = (taskIndex) => {
    this.setState({ selectedDay: null, notesIsOpen: true, selectedIndex: taskIndex, selectedSubtaskIndex: null })
  }

  openSubtaskNotes = (taskIndex, subtaskIndex) => {
    this.setState({ selectedDay: null, notesIsOpen: true, selectedIndex: taskIndex, selectedSubtaskIndex: subtaskIndex })
  }

  onCloseSubtaskCreation = () => {
    this.setState({ subtaskCreationIsOpen: false }, () => {
      this.setState({ subtaskCreationSelectedTaskIndex: null })
    })
  }

  openCreateSubtask = (taskIndex) => {
    this.setState({ subtaskCreationIsOpen: true, subtaskCreationSelectedTaskIndex: taskIndex })
  }

  isTaskDisabled = (timelog, isDisabled) => {
    return hasSubtasks(timelog) || isDisabled
  }

  renderInputTooltip = (timelog, index, day, isDisabled) => {
    const timelogComment = timelog.comments && timelog.comments[day] ? timelog.comments[day] : 'No comment added'
    let hoursWorked = timelog[day]
    if (hasSubtasks(timelog)) {
      hoursWorked = sumOfSubtasksForDay(timelog, day) || ''
    }

    return (
      <div className="d-flex align-items-center">
        <input
          name={`${index}_${day}`}
          disabled={this.isTaskDisabled(timelog, isDisabled)}
          className="bp3-input input"
          type="text"
          dir="auto"
          onChange={this.handlerTimeChange}
          value={hoursWorked}
          onBlur={(event) => this.handleTimeLogChange(timelog, event)}
          onDoubleClick={() => this.openDayComment(index, day)}
        />
        {timelog.comments && timelog.comments[day] &&
          <div className="day_comment" onClick={() => this.openDayComment(index, day)}>
            <Tooltip content={timelogComment} position={Position.TOP}>
              <span className="icon-comment" role="img" aria-label="comment">💬</span>
            </Tooltip>
          </div>
        }
      </div>
    )
  }

  renderInputSubtaskTooltip = (timelog, index, subtaskIndex, day, isDisabled) => {
    const subtask = timelog.subtasks[subtaskIndex]
    const timelogComment = subtask.comments && subtask.comments[day] ? subtask.comments[day] : 'No comment added'

    return (
      <div className="d-flex align-items-center">
        <input
          name={`${index}_${subtaskIndex}_${day}`}
          disabled={isDisabled}
          className="bp3-input input"
          type="text"
          dir="auto"
          onChange={this.handlerSubtaskChange}
          value={subtask[day]}
          onBlur={() => this.saveTimelog(timelog)}
          onDoubleClick={() => this.openDaySubtaskComment(index, subtaskIndex, day)}
        />
        {subtask.comments && subtask.comments[day] &&
          <div className="day_comment" onClick={() => this.openDaySubtaskComment(index, subtaskIndex, day)}>
            <Tooltip content={timelogComment} position={Position.TOP}>
              <span className="icon-comment" role="img" aria-label="comment">💬</span>
            </Tooltip>
          </div>
        }
      </div>
    )
  }

  render() {
    const { timesheet, user, currentYear, currentWeek, hoursByWeek, paydays } = this.props
    const { timelogs, notesIsOpen, subtaskCreationIsOpen, selectedDay, selectedIndex, selectedSubtaskIndex, subtaskCreationSelectedTaskIndex } = this.state

    let content = <EmptyData
      title="This timesheet is empty"
      description="Add task and time to complete a timesheet." />

    if (timelogs && timelogs.length > 0) {
      const isSend = (timesheet && timesheet.status === 'sent')
      const isApproved = (timesheet && timesheet.status === 'approved')
      const isDisabled = (isSend || isApproved)

      const totalHours = this.getTotalTimeOfWeek(timelogs)
      const bankHours = this.getBankTaskTotalTimeOfWeek()

      /*
      Description :
      If the month of January begins with a Friday, Saturday or Sunday,
      then the week is shifted, so we must not subtract 1 week from it,
      else, we must subtract a week.
      The problem is apparently due because the week of the calendar begin with Monday instead of Sunday.

      Example : 2021, 2022, 2023, 2027, 2028, 2033, 2034, 2038, 2039, 2040.

      - 2021 : Friday
      - 2022 : Saturday
      - 2023 : Sunday
      2024 : Monday
      2025 : Wednesday
      2026 : Thursday
      - 2027 : Friday
      - 2028 : Saturday
      2029 : Monday
      2030 : Tuesday
      2031 : Wednesday
      2032 : Thursday
      - 2033 : Saturday
      - 2034 : Sunday
      2035 : Monday
      2036 : Tuesday
      2037 : Thursday
      - 2038 : Friday
      - 2039 : Saturday
      - 2040 : Sunday

      legend:
      - year => year that start with Friday, Saturday or Sunday.
      */

      const daysToAddOneWeek = ['FRIDAY', 'SATURDAY', 'SUNDAY']
      const firstDayOfYear = moment(currentYear, 'YYYY').format('dddd').toUpperCase()
      let oneWeek = 1

      if (daysToAddOneWeek.includes(firstDayOfYear)) {
        oneWeek = 0
      }

      const currentDay = moment(currentYear, 'YYYY').add(currentWeek - oneWeek, 'weeks').startOf('isoweek')

      content =
        <div width="100%">
          <div className="timesheet-user-info-container">
            <div className="avatar-name-container">
              <div>
                <Avatar size={50} round={true} src={user.avatarUrl} />
              </div>
              <div style={{ paddingLeft: '10px' }}>
                <h3 className="bp3-heading">{user.displayName}</h3>
              </div>
            </div>

            <div className="status-container">
              <h3 className="bp3-heading" style={{ margin: 0 }}>
                <TimeSheetStatus
                  status={timesheet && timesheet.status}
                  date={timesheet && timesheet.date} />
              </h3>
            </div>
            <div className="status-container">
              <h3 className="bp3-heading"><span >{Math.min(totalHours - bankHours, hoursByWeek) + bankHours} paid hours</span></h3>
            </div>
          </div>

          <Table className="bp3-html-table bp3-html-table-bordered">
            <Thead>
              <Tr>
                <Th>Tasks</Th>
                {
                  DateUtils.DAYS.map((day) => {
                    const emoji = DateUtils.getEmojiFromDayForTimesheet(paydays, currentDay)
                    const th = <Th key={day}>{toTitleCase(day)}<br /> {currentDay.format(DateUtils.SHORT_DAY_AND_MONTH_FORMAT)} <br /> {emoji}</Th>
                    currentDay.add(1, 'days')
                    return th
                  })
                }
                <Th>Total</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                timelogs.map((timelog, index) => {
                  currentDay.subtract(1, 'week')
                  return (
                    <React.Fragment key={index}>
                      <Tr width="30">
                        <Td disabled>
                          <div className="task-logo-container">
                            <Tooltip
                              content={this.getTaskDesc(timelog)}
                              position={Position.RIGHT}>
                              {this.getTaskDisplayName(timelog)}
                            </Tooltip>
                            {this.renderTaskLogoImg(timelog)}
                          </div>
                        </Td>
                        {
                          DateUtils.DAYS.map((day) => {
                            currentDay.add(1, 'days')
                            return (
                              <Td key={`${index}_${day}`}>
                                <div className="input-container">
                                  {this.renderInputTooltip(timelog, index, day, isDisabled)}
                                </div>
                              </Td>
                            )
                          })
                        }
                        <Td><input name={`${index}_total`} className="bp3-input input" disabled type="text" dir="auto" value={this.getTaskTotalTimeOfWeek(timelog)} /></Td>
                        <Td>
                          <div className="buttons-container">
                            <Button icon="trash" intent="danger" onClick={() => this.deleteTimelog(timelog)} disabled={this.isTaskDisabled(timelog, isDisabled)}></Button>
                            <Button className="ml-1" icon="comment" intent="primary" onClick={() => this.openNotes(index)} />
                            <Button icon="insert" className="ml-1" intent="success" onClick={() => this.openCreateSubtask(index)} />
                          </div>
                        </Td>
                      </Tr>
                      {timelog.hasOwnProperty('subtasks') ?
                        timelog.subtasks.map((subtask, subtaskIndex) => {
                          return (
                            <Tr key={`${index}_${subtaskIndex}`} width="30">
                              <Td disabled>
                                <div className="task-logo-container subtask-name">
                                  <div>
                                    {this.getSubtaskDisplayName(timelog, subtask)}
                                  </div>
                                  {this.renderSubtaskLogoImg(timelog, subtask)}
                                </div>
                              </Td>
                              {
                                DateUtils.DAYS.map((day) => {
                                  currentDay.add(1, 'days')
                                  return (
                                    <Td key={`${index}_${subtaskIndex}_${day}`}>
                                      <div className="input-container">
                                        {this.renderInputSubtaskTooltip(timelog, index, subtaskIndex, day, isDisabled)}
                                      </div>
                                    </Td>
                                  )
                                }
                                )
                              }
                              <Td><input name={`${index}_total`} className="bp3-input input" disabled type="text" dir="auto" value={this.getTaskTotalTimeOfWeek(subtask)} /></Td>
                              <Td>
                                <div className="buttons-container">
                                  <Button icon="trash" intent="danger" onClick={() => this.deleteSubtask(timelog, subtaskIndex)} disabled={isDisabled}></Button>
                                  <Button icon="comment" className="ml-1" intent="primary" onClick={() => this.openSubtaskNotes(index, subtaskIndex)} />
                                </div>
                              </Td>
                            </Tr>
                          )
                        })
                        : <React.Fragment />}
                    </React.Fragment>
                  )
                }
                )
              }
              <Tr>
                <Td>Total</Td>
                {
                  DateUtils.DAYS.map((day) =>
                    <Td key={day}><input className="bp3-input input" disabled type="text" dir="auto" value={this.getTotalTimeOfDay(day)} /></Td>
                  )
                }
                <Td>
                  <input
                    className="bp3-input input"
                    disabled type="text"
                    dir="auto"
                    value={totalHours}
                    style={{ color: 'Black', backgroundColor: (totalHours >= hoursByWeek ? 'MediumSeaGreen' : 'Tomato') }} />
                </Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
          <TaskNote
            taskIndex={selectedIndex}
            subtaskIndex={selectedSubtaskIndex}
            timelog={timelogs[selectedIndex]}
            taskDescription={selectedSubtaskIndex == null ?
              this.getTaskDisplayName(timelogs[selectedIndex]) :
              this.getSubtaskDisplayName(timelogs[selectedIndex], timelogs[selectedIndex].subtasks[selectedSubtaskIndex])}
            currentWeek={currentWeek}
            currentYear={currentYear}
            onTimeChange={selectedSubtaskIndex === null ? this.handlerTimeChange : this.handlerSubtaskChange}
            onSaveTimelog={this.saveTimelog}
            isOpen={notesIsOpen}
            days={selectedDay ? [selectedDay] : undefined}
            onClose={this.onClose}
          />
          <SubtaskCreationDialog
            taskIndex={subtaskCreationSelectedTaskIndex}
            taskName={this.getTaskDisplayName(timelogs[subtaskCreationSelectedTaskIndex])}
            onAddSubtask={this.onAddSubtask}
            onNewSubtask={this.onNewSubtask}
            isOpen={subtaskCreationIsOpen}
            onClose={this.onCloseSubtaskCreation}
            existingSubtasks={this.getExistingSubtasks()}
          />
        </div>
    }

    return (
      <div className="timesheet-table">
        <div className="bp3-card bp3-elevation-2">
          {content}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

TimesheetTable.propTypes = propTypes

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetTable)
