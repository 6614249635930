import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect, populate } from 'react-redux-firebase'
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core'
import * as actions from '../../store/actions'
import AchievmentCard from '../../components/AchievementCard/AchievementCard'
import AchievementUsers from '../../components/AchievementUsers/AchievementUsers'
import Select from '../../components/Select/Select'
import {
  getObjectValues,
  orderDescendingArray
} from '../../Utils'

import './Achievements.css'

export class Achievements extends Component {

  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      achievements: this.props.achievements,
      achievementslogs: this.props.achievementslogs,
      users: this.props.users,
      autoFocus: true,
      canEscapeKeyClose: true,
      canOutsideClickClose: true,
      enforceFocus: true,
      isOpen: false,
      userValue: null,
      userSelected: null,
      achievementValue: null,
      achievementSelected: null,
      descriptionText: '',
    }
  }

  componentWillReceiveProps(newProps) {
    const state = this.state
    state.achievements = newProps.achievements
    state.achievementslogs = newProps.achievementslogs
    state.users = newProps.users
    this.setState(state)
  }

  handlerAddAchievement = () => {
    const { user } = this.props
    const { descriptionText, achievementSelected, userSelected } = this.state

    this.props.firebase
      .push('achievementslogs/', {
        description: descriptionText,
        message: achievementSelected.message,
        name: achievementSelected.name,
        date: Date.now(),
        from_avatar: user.avatarUrl,
        from_display_name: user.displayName,
        from_id: user.uid,
        to_avatar: userSelected.avatarUrl,
        to_display_name: userSelected.displayName,
        to_id: userSelected.uid,
      })
      .then((result) => {
        const key = result.key
        this.props.firebase
          .set('achievementslogs/' + key + '/id', key)
          .then(() => {
            this.handleClose()
          })
          .catch((error) => {
            this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
          })
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerAddLike = (achievementslogsId) => {
    const { user } = this.props

    this.props.firebase
      .push('achievementslogs/' + achievementslogsId + '/likes/', {
        user_display_name: user.displayName,
      })
      .then((result) => {
        const key = result.key
        this.props.firebase
          .set('achievementslogs/' + achievementslogsId + '/likes/' + key + '/id', key)
          .then(() => {
          })
          .catch((error) => {
            this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
          })
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerDeleteLike = (likeId, achievementslogsId) => {
    this.props.firebase
      .ref('achievementslogs/' + achievementslogsId + '/likes/' + likeId)
      .remove()
      .then(() => {
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerAddComment = (comment, achievementslogsId) => {
    const { user } = this.props

    this.props.firebase
      .push('achievementslogs/' + achievementslogsId + '/comments/', {
        user_id: user.uid,
        user_display_name: user.displayName,
        user_avatar: user.avatarUrl,
        date: Date.now(),
        text: comment
      })
      .then((result) => {
        const key = result.key
        this.props.firebase
          .set('achievementslogs/' + achievementslogsId + '/comments/' + key + '/id', key)
          .then(() => {
          })
          .catch((error) => {
            this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
          })
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerDeleteComment = (comment, achievementslogsId) => {
    this.props.firebase
      .ref('achievementslogs/' + achievementslogsId + '/comments/' + comment.id)
      .remove()
      .then(() => {
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerDeleteAchievement = (achievementslogsId) => {

    this.props.firebase
      .ref('achievementslogs/' + achievementslogsId)
      .remove()
      .then(() => {

      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error:' + error.message)
      })
  }

  handleOpen = () => {
    const state = this.state
    state.isOpen = true
    this.setState(state)
  }

  handleClose = () => {
    const state = this.state
    state.isOpen = false
    state.userSelected = null
    state.userValue = null
    state.achievementSelected = null
    state.achievementValue = null
    state.descriptionText = ''
    this.setState(state)
  }

  handlerSelectUserChange = (event) => {
    const user = this.state.users[event.target.value]
    if (user && user.uid !== '') {
      const state = this.state
      state.userSelected = user
      this.setState(state)
    }
    else {
      const state = this.state
      state.userSelected = null
      this.setState(state)
    }
  }

  handlerSelectAchievementChange = (event) => {
    const achievement = this.state.achievements[event.target.value]
    if (achievement && achievement.id !== '') {
      const state = this.state
      state.achievementSelected = achievement
      this.setState(state)
    }
    else {
      const state = this.state
      state.achievementSelected = null
      this.setState(state)
    }
  }

  handlerUpdateDescriptionText = (event) => {
    const state = this.state
    state.descriptionText = event.target.value
    this.setState(state)
  }

  render() {
    const { achievementslogs, users, userValue, achievements, achivementValue, descriptionText } = this.state
    const { user } = this.props

    const userOptions = users.map((auser, index) => {
      return {
        text: auser.displayName + ' - ' + auser.email,
        value: index,
      }
    })

    const achievementOptions = []
    let pos = 0
    for (const achievement of achievements) {
      if (achievement.type === 'MANUAL' || user.role === 'admin' || user.role === 'manager') {
        achievementOptions.push({
          text: achievement.description,
          value: pos,
        })
      }
      pos++
    }

    return (
      <div className="row achievements-container" style={{ width: '100%' }}>
        <Dialog
          className="bp3-dark"
          icon="endorsed"
          onClose={this.handleClose}
          title="Give an Achievement"
          {...this.state}>
          <div className={Classes.DIALOG_BODY}>
            <Select
              labelText="Collegue:"
              defaultText="Select a collegue ..."
              options={userOptions}
              value={userValue}
              onChange={this.handlerSelectUserChange} />
            <Select
              labelText="Achievement:"
              defaultText="Select a achievement ..."
              options={achievementOptions}
              value={achivementValue}
              onChange={this.handlerSelectAchievementChange} />
            Description:
            <textarea className="bp3-input bp3-fill" dir="auto" onChange={this.handlerUpdateDescriptionText} value={descriptionText} />
            <Button style={{ marginTop: '10px', float: 'right' }} onClick={this.handlerAddAchievement}>Save</Button>
          </div>
        </Dialog>
        <div className="col-md-7 col-12" style={{ padding: '20px' }}>
          <div style={{ paddingRight: '20px' }}>
            <Button icon="endorsed" style={{ float: 'right' }} onClick={this.handleOpen}>Give an Achievement</Button>
          </div>
          <h2 className="bp3-heading" style={{ paddingLeft: '20px' }}>Last achievements</h2>
          {achievementslogs.map((achievement, index) => {
            if (index < 10) {
              return (
                <div key={achievement.id} style={{ padding: '20px' }}>
                  <AchievmentCard {...achievement}
                    key={achievement.id}
                    user={user}
                    onAddComment={this.handlerAddComment}
                    onDeleteComment={this.handlerDeleteComment}
                    onAddLike={this.handlerAddLike}
                    onDeleteLike={this.handlerDeleteLike}
                    onDeleteAchievement={this.handlerDeleteAchievement} />
                </div>
              )
            }
            else {
              return (null)
            }
          })}
        </div>
        <div className="col-md-5 col-12" style={{ padding: '20px' }}>
          <h2 className="bp3-heading" style={{ paddingBottom: '10px' }}>Top Unicorn</h2>
          <div classeName="achievements-scores-container">
            <AchievementUsers
              users={users}
              achievementslogs={achievementslogs} />
          </div>
        </div>
      </div>
    )
  }
}

const populates = [
  { child: 'supervisor', root: 'users' }
]

const mapStateToProps = (state) => {
  return {
    user: state.firebase.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  firebaseConnect((props) => [
    'achievements',
    'achievementslogs',
    { path: 'users', populates },
  ]),
  connect((state, props) => ({
    achievements: getObjectValues(state.firebase.data.achievements),
    achievementslogs: orderDescendingArray(getObjectValues(state.firebase.data.achievementslogs), 'date'),
    users: getObjectValues(populate(state.firebase, 'users', populates)),
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(Achievements)