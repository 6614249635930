import React, { Component } from 'react'
import { Position, Tooltip } from '@blueprintjs/core'
import moment from 'moment'

import { toTitleCase } from '../../Utils'
import DateUtils from '../../DateUtils'

class TimesheetStatus extends Component {
  render() {
    const status = this.props.status
    const date = this.props.date

    let content =
      <span className="bp3-tag bp3-large bp3-minimal bp3-intent-danger">
        Timesheet not sent yet!
      </span>

    if (status === 'sent' || status === 'approved') {
      content =
        <span className="bp3-tag bp3-large bp3-minimal bp3-intent-success">
          <Tooltip content={moment(date).format(DateUtils.LONG_DATE_FORMAT)} position={Position.TOP}>{`${toTitleCase(status)} ${moment(date).fromNow()}`}</Tooltip>
        </span>
    }

    return content
  }
}

export default TimesheetStatus