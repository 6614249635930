import React, { Component } from 'react'
import { Intent, Checkbox } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'

import * as actions from '../../../store/actions'
import {
  formatTimelogsForGeneralReport,
  formatTimelogsForWeeklyReport,
  formatTitle,
  getTasks,
  getTimelogsForUser,
  groupTimelogsByTasks
} from '../../../Utils'
import SummaryHeader from '../../../components/SummaryHeader/SummaryHeader'
import SummaryTable from '../../../components/SummaryTable/SummaryTable'
import SummaryGraph from '../../../components/SummaryGraph/SummaryGraph'

import './Summary.css'

class Summary extends Component {

  state = {
    loading: false,
    results: [],
    currentBeginDate: null,
    currentEndDate: null,
    showSubtasks: false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== this.props.user) {
      // The user has changed, update the report!
      this.runReport(this.state.currentBeginDate, this.state.currentEndDate)
    }
  }

  runReport = (beginDate, endDate) => {
    this.setState({
      loading: true,
      results: [],
      currentBeginDate: beginDate,
      currentEndDate: endDate,
    })

    getTimelogsForUser(this.props.user, this.props.firebase, this.props.tasks, beginDate, endDate)
      .then((results) => {
        this.setState({
          loading: false,
          results: results,
        })
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handleShowSubtasksToggle = () => {
    this.setState({
     showSubtasks: !this.state.showSubtasks
    })
  }

  showUserWeeklyTimelogs = (result, index) => {
    const { currentBeginDate, currentEndDate } = this.state

    if (result.timelogs.length > 0) {
      return <SummaryTable
        key={index}
        title={formatTitle(result.displayName, currentBeginDate, currentEndDate)}
        results={formatTimelogsForWeeklyReport(result.timelogs, this.state.showSubtasks, currentBeginDate, currentEndDate)}
      />
    }

    return null
  }

  renderGeneralReport = () => {
    const { results, loading } = this.state
    return (
      <React.Fragment>
        <SummaryTable
          loading={loading}
          results={formatTimelogsForGeneralReport(this.state.showSubtasks, groupTimelogsByTasks(results))}/>
        <SummaryGraph
          loading={loading}
          results={groupTimelogsByTasks(results)} />
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        <div className="summary-leftsection">
          <SummaryHeader
            onClick={this.runReport} />
        </div>
        <div className="summary-mainsection">
          <Checkbox
            label="Show tasks and subtasks"
            checked={this.state.showSubtasks}
            onChange={this.handleShowSubtasksToggle}
          />
          <SummaryTable
            results={formatTimelogsForGeneralReport(this.state.showSubtasks, groupTimelogsByTasks([this.state.results]))}
            loading={this.state.loading} />
          <SummaryGraph
            results={groupTimelogsByTasks([this.state.results])}
            loading={this.state.loading} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.timesheet.user ? state.timesheet.user : state.firebase.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  firebaseConnect((props) => [
    'tasks',
  ]),
  connect((state, props) => ({
    tasks: getTasks(state.firebase.data.tasks),
  })),
  connect(mapStateToProps, mapDispatchToProps),
)(Summary)
