import { Button } from '@blueprintjs/core'
import React, { Component } from 'react'
import { downloadCSV } from '../../Utils'
import EmptyData from '../EmptyData/EmptyData'
import Spinner from '../Spinner/Spinner'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'

import './SummaryTable.css'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

class SummaryTable extends Component {

  render() {
    const { results, loading, title } = this.props

    let content = <EmptyData
      title="This report is empty"
      description="Add more date"
    />

    if (loading) {
      content = <Spinner />
    }
    else if (results && results.length > 0) {
      const keys = this.props.keys || Object.keys(results[0])
      content =
        <div>
          <div className="summary-table-header">
            <h3>{title}</h3>
            <Button icon="export" text="Export" style={{ float: 'right' }} onClick={() => downloadCSV(results, keys, title)} />
          </div>
          <Table className="bp3-html-table bp3-interactive">
            <Thead>
              <Tr>
                {keys.map((key) => <Th key={key}>{key}</Th>)}
              </Tr>
            </Thead>
            <Tbody>
              {
                results.map((res, index) =>
                  <Tr key={index}>
                    {keys.map((key) => <Td key={key}>{res[key]}</Td>)}
                  </Tr>
                )
              }
            </Tbody>
          </Table>
        </div>
    }

    return (
      <div className="summary-table">
        <div className="bp3-card bp3-elevation-2">
          {content}
        </div>
      </div>
    )
  }
}

export default SummaryTable
