import React, { Component } from 'react'
import moment from 'moment'
import { Position, Tooltip } from '@blueprintjs/core'
import DateUtils from '../../DateUtils'
import AchievementLeft from './AchievementLeft'
import AchievementMain from './AchievementMain'
import AchievementComment from './AchievementComment'
import AchievementAddComment from './AchievementAddComment'
import { getObjectValues } from '../../Utils'

import './AchievementCard.css'

class AchievementCard extends Component {

  state = {
    commentText: ''
  }

  render() {

    const { name, date, comments } = this.props
    const commentsArray = getObjectValues(comments)

    return (
      <div className="bp3-card bp3-elevation-2 element-message">
        <div className="flex">
          <div className="row">
            <div className="col-12">
              <i style={{ fontSize: '10px', float: 'right', paddingTop: '12px' }}>Received <Tooltip content={moment(date).format(DateUtils.LONG_DATE_FORMAT)} position={Position.TOP}>{moment(date).fromNow()}</Tooltip></i>
              <h3 className="bp3-heading">
                <div style={{ paddingTop: '10px' }}>
                  <span className="bp3-icon bp3-icon-endorsed" style={{ paddingRight: '10px' }}></span>{name}
                </div>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-1" style={{ textAlign: 'center' }}>
              <AchievementLeft {...this.props} />
            </div>
            <div className="col-11">
              <AchievementMain {...this.props} />
              {
                commentsArray &&
                commentsArray.map((comment, index) => {
                  return (<AchievementComment key={index} {...this.props} comment={comment} />)
                })
              }
              <AchievementAddComment {...this.props} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AchievementCard