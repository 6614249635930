import React, { Component } from 'react'
import { Button, Switch, InputGroup } from '@blueprintjs/core'
import { key } from 'firebase-key'
import { isEqual, orderBy } from 'lodash'

import AdminEditDialog from '../../components/AdminEditDialog/AdminEditDialog'

import './AdminTasksTable.css'

class AdminTasksTable extends Component {

  // Used props : tasks, updateTask(), taskType, taskDisplayName.
  state = {
    SelectedTask: null,
    isOpen: false,
    areColumsSorted: {
      name: false,
      desc: false,
      active: false,
    },
    searchFilter: '',
    tasks: this.props.tasks
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ tasks: this.props.tasks })
    }
  }

  onSwitchChanged = (event, index) => {
    this.props.updateTask({ ...this.props.tasks[index], active: event.target.checked })
  }

  handleOpen = (task) => this.setState({ isOpen: true, SelectedTask: { ...task } });
  handleClose = () => this.setState({ isOpen: false, SelectedTask: null });
  handleSave = (task) => this.props.updateTask(task);
  handleAdd = () => {
    const newTask = { active: true, desc: '', id: key(), name: '', type: this.props.taskType }
    this.setState({ isOpen: true, SelectedTask: newTask })
  }

  onSearchFilterChange = (event) => {
    this.setState({ searchFilter: event.target.value })
  }

  handleOnClickDeleteFilter = () => {
    this.setState({ searchFilter: '' })
  }

  taskNameStartsWith = (taskName, searchFilter) => {
    const name = taskName.toUpperCase()
    const filter = searchFilter.toUpperCase()
    return name.startsWith(filter)
  }

  filterTasks = (tasks, filter) => {
    if (tasks) {
      return tasks.filter((task) => this.taskNameStartsWith(task.name, filter))
    }
    return tasks
  }

  sortColumn = (key) => {
    const tasks = this.state.tasks
    const sortOrder = this.state.areColumsSorted[key] ? 'desc' : 'asc'
    const sortedTasks = orderBy(tasks, [(task) => task[key].toString().toLowerCase()], [sortOrder])
    const columns = { ...this.state.areColumsSorted }
    Object.keys(columns).forEach((innerKey) => {
      if (key === innerKey) {
        columns[innerKey] = !columns[innerKey]
      } else {
        columns[innerKey] = false
      }
    })
    this.setState({
      tasks: sortedTasks,
      areColumsSorted: columns
    })
  }

  render() {
    const taskDisplayName = this.props.taskDisplayName || this.props.taskType
    const filter = this.state.searchFilter
    let tasks = this.state.tasks
    tasks = this.filterTasks(tasks, filter)

    const deleteFilterButton = <Button icon="cross" onClick={this.handleOnClickDeleteFilter} minimal={true} />

    return (
      <div>
        <div>
          <Button
            icon="add"
            text={'Add ' + taskDisplayName}
            intent="success"
            onClick={this.handleAdd}
          />
        </div>
        <div style={{ paddingTop: '15px', paddingBottom: '5px' }}>
          <InputGroup
            className="filter-container"
            placeholder="Filter by name"
            leftIcon="filter"
            onChange={this.onSearchFilterChange}
            value={filter}
            rightElement={deleteFilterButton}
          />
        </div>
        <table className="bp3-html-table bp3-interactive bp3-html-table-striped" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th className="name-column" style={{ width: '30%' }}>
                <div className="header-column-container">
                  Name
                  {
                    this.state.areColumsSorted['name'] ?
                      <Button icon="sort-alphabetical-desc" minimal={true} onClick={() => this.sortColumn('name')} /> :
                      <Button icon="sort-alphabetical" minimal={true} onClick={() => this.sortColumn('name')} />
                  }
                </div>
              </th>
              <th className="desc-column" style={{ width: '70%' }}>
                <div className="header-column-container">
                  Description
                  {
                    this.state.areColumsSorted['desc'] ?
                      <Button icon="sort-alphabetical-desc" minimal={true} onClick={() => this.sortColumn('desc')} /> :
                      <Button icon="sort-alphabetical" minimal={true} onClick={() => this.sortColumn('desc')} />
                  }
                </div>
              </th>
              <th>
                <div className="header-column-container">
                  Active
                  {
                    this.state.areColumsSorted['active'] ?
                      <Button icon="sort-desc" minimal={true} onClick={() => this.sortColumn('active')} /> :
                      <Button icon="sort-asc" minimal={true} onClick={() => this.sortColumn('active')} />
                  }
                </div>
              </th>
              <th />{/* Edit button */}
            </tr>
          </thead>
          <tbody>
            {
              tasks.map((task, index) => {
                return (
                  <tr key={index}>
                    <td>{task.name}</td>
                    <td>{task.desc}</td>
                    <td><Switch checked={task.active} onChange={(e) => this.onSwitchChanged(e, index)} /></td>
                    <td><Button icon="edit" onClick={() => this.handleOpen(task)}></Button></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

        <AdminEditDialog
          title={taskDisplayName}
          isOpen={this.state.isOpen}
          handleClose={this.handleClose}
          handleSave={this.handleSave}
          task={this.state.SelectedTask}
        />
      </div>
    )
  }
}

export default AdminTasksTable