import React from 'react'
import ReactMarkdown from 'react-markdown'
import './Comment.css'

const Comment = (props) => {
  return (
    <div className="message-box">
      <ReactMarkdown source={props.message} />
    </div>
  )
}

export default Comment