import React, { Component } from 'react'

import './AdminTitleInfo.css'

class AdminTitleInfo extends Component {
  render() {
    return (
      <div>
        <h1 className="bp3-heading">{this.props.title}</h1>
        <div className="informations">
          <div className="bp3-callout bp3-intent-primary">
            <h4 className="bp3-heading">Informations</h4>
            {this.props.info}
          </div>
        </div>
      </div>
    )
  }
}

export default AdminTitleInfo