import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Toaster, Position } from '@blueprintjs/core'

import * as actions from '../../store/actions'

class WrapToaster extends Component {

  componentDidUpdate(prevProps, prevState) {
    if (this.props.toastQueue.size) {
      this.props.toastQueue.forEach((toast) => {
        this.toaster.show(toast)
      })
      this.props.onClear()
    }
  }

  refHandlers = {
    toaster: (el) => (this.toaster = el),
  };

  render() {
    return (
      <div>
        <Toaster position={Position.TOP_CENTER} ref={this.refHandlers.toaster} />
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    toastQueue: state.ui.toastQueue,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
    onClear: () => dispatch(actions.clearToasts())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrapToaster)