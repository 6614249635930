import React, { Component } from 'react'
import { Checkbox, Intent, Tabs, Tab } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'

import * as actions from '../../../store/actions'

import {
  getTimelogsForUsers,
  formatTimelogsForWeeklyReport,
  formatTitle,
  toTitleCase,
  formatTimelogsForGeneralReport,
  groupTimelogsByTasks,
  getUsers,
  getTasks
} from '../../../Utils'
import Select from '../../../components/Select/Select'
import SummaryHeader from '../../../components/SummaryHeader/SummaryHeader'
import SummaryTable from '../../../components/SummaryTable/SummaryTable'
import SummaryGraph from '../../../components/SummaryGraph/SummaryGraph'
import AdminTitleInfo from '../../../components/AdminTitleInfo/AdminTitleInfo'
import EmptyData from '../../../components/EmptyData/EmptyData'
import Spinner from '../../../components/Spinner/Spinner'

export class Reports extends Component {

  state = {
    users: [],
    selectedUser: null,
    tasks: [],
    selectedTask: null,
    taskTypes: [],
    selectedTaskType: null,
    results: [],
    loading: false,
    currentBeginDate: null,
    currentEndDate: null,
    activeTabId: 'weeklyreport',
    showSubtasks: false
  }

  runReport = (beginDate, endDate) => {
    this.setState({
      loading: true,
      results: [],
      currentBeginDate: beginDate,
      currentEndDate: endDate,
    })

    return getTimelogsForUsers(this.state.selectedUser ? [this.state.selectedUser] : this.props.users, this.props.firebase, this.props.tasks, beginDate, endDate)
      .then((users) => {
        this.filterUsersTimelogs(users)
        this.setState({
          loading: false,
          results: users,
        })
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  filterUsersTimelogs = (users) => {
    users.forEach((user) => {
      // Filter on selected task type
      if (this.state.selectedTaskType) {
        user.timelogs = user.timelogs.filter((timelog) => timelog.task_type === this.state.selectedTaskType)
      }
      // Filter on selected task
      if (this.state.selectedTask) {
        user.timelogs = user.timelogs.filter((timelog) => timelog.task_id === this.state.selectedTask.id)
      }
    })
  }

  timelogsAreNotEmpty = (results) => {
    return results.reduce((acc, result) => acc + result.timelogs.length, 0)
  }

  handleSelectUserChange = (event) => {
    this.handleSelectChange(event, 'selectedUser', this.props.users)
  }

  handleSelectTaskChange = (event) => {
    this.handleSelectChange(event, 'selectedTask', this.props.tasks)
  }

  handleSelectTaskTypeChange = (event) => {
    this.handleSelectChange(event, 'selectedTaskType', this.props.taskTypes)
  }

  handleSelectChange = (event, selectedItem, items) => {
    if (Number(event.target.value) === -1) {
      this.setState({
        [selectedItem]: null,
      }, () => this.runReport(this.state.currentBeginDate, this.state.currentEndDate))
    }
    else {
      const item = items[event.target.value]
      if (item) {
        this.setState({
          [selectedItem]: item,
        }, () => this.runReport(this.state.currentBeginDate, this.state.currentEndDate))
      }
      else {
        this.props.onToast('error', Intent.DANGER, 'Cannot retrieved the selected item.')
      }
    }
  }

  handleTabChange = (activeTabId) => {
    this.setState({ activeTabId })
  }

  handleShowSubtasksToggle = () => {
    this.setState({
     showSubtasks: !this.state.showSubtasks
    })
  }

  showUserWeeklyTimelogs = (result, index) => {
    const { currentBeginDate, currentEndDate } = this.state

    if (result.timelogs.length > 0) {
      return <SummaryTable
        key={index}
        title={formatTitle(result.displayName, currentBeginDate, currentEndDate)}
        results={formatTimelogsForWeeklyReport(result.timelogs, this.state.showSubtasks, currentBeginDate, currentEndDate)}
      />
    }

    return null
  }

  renderWeeklyReport = () => {
    const { results, loading } = this.state

    if (loading) {
      return <Spinner />
    }

    if (this.timelogsAreNotEmpty(results)) {
      return <React.Fragment>
        {results.map((result, index) => this.showUserWeeklyTimelogs(result, index))}
      </React.Fragment>
    }

    return <EmptyData
      title="This report is empty"
      description="Add more date"
    />
  }

  renderGeneralReport = () => {
    const { results, loading } = this.state
    return (
      <React.Fragment>
        <SummaryTable
          loading={loading}
          results={formatTimelogsForGeneralReport(this.state.showSubtasks, groupTimelogsByTasks(results))} />
        <SummaryGraph
          loading={loading}
          results={groupTimelogsByTasks(results)} />
      </React.Fragment>
    )
  }

  render() {
    // Users
    const userOptions = this.props.users.map((user, index) => {
      return {
        text: user.displayName + ' - ' + user.email,
        value: index,
      }
    })
    const userValue = this.state.selectedUser ? this.props.users.findIndex((user) => user.uid === this.state.selectedUser.uid) : -1

    // Tasks
    const taskOptions = this.props.tasks ?
      this.props.tasks.map((task, index) => {
        return {
          text: toTitleCase(task.type) + '/' + task.name,
          value: index
        }
      }) :
      []
    const taskValue = this.state.selectedTask ? this.props.tasks.findIndex((task) => task.id === this.state.selectedTask.id) : -1

    // Task types
    const taskTypeOptions = this.props.tasks ?
      this.props.taskTypes.map((taskType, index) => {
        return {
          text: toTitleCase(taskType),
          value: index
        }
      }) :
      []
    const taskTypeValue = this.state.selectedTaskType ? this.props.taskTypes.findIndex((taskType) => taskType === this.state.selectedTaskType) : -1

    return (
      <div>
        <AdminTitleInfo
          title="Reports"
          info="Generate time reports for every users." />
        <table width="100%">
          <tbody>
            <tr>
              <td width="70%">
                <SummaryHeader
                  onClick={this.runReport} />
              </td>
              <td width="30%" style={{ verticalAlign: 'top' }}>
                <Select
                  labelText="Team members:"
                  defaultText="All users"
                  options={userOptions}
                  value={userValue}
                  onChange={this.handleSelectUserChange} />
                <Select
                  labelText="Tasks:"
                  defaultText="All tasks"
                  options={taskOptions}
                  value={taskValue}
                  onChange={this.handleSelectTaskChange} />
                <Select
                  labelText="Task Types:"
                  defaultText="All task types"
                  options={taskTypeOptions}
                  value={taskTypeValue}
                  onChange={this.handleSelectTaskTypeChange} />
                {this.state.activeTabId === 'weeklyreport' ?
                 <Checkbox
                 label="Show tasks and subtasks"
                 checked={this.state.showSubtasks}
                 onChange={this.handleShowSubtasksToggle}
                 /> :
                 <React.Fragment />
                }
              </td>
            </tr>
          </tbody>
        </table>
        <div>

          <Tabs id="TabsReport" onChange={this.handleTabChange} selectedTabId={this.state.activeTabId}>
            <Tab id="weeklyreport" title="Weekly" panel={this.renderWeeklyReport()} />
            <Tab id="generalreport" title="General" panel={this.renderGeneralReport()} />
            <Tabs.Expander />
          </Tabs>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  firebaseConnect((props) => [
    'users',
    'tasks',
  ]),
  connect((state, props) => ({
    users: getUsers(state.firebase.data.users),
    tasks: getTasks(state.firebase.data.tasks),
    taskTypes: state.firebase.data.tasks ? Object.keys(state.firebase.data.tasks.task_types || {}) : [],
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(Reports)
