import React, { Component } from 'react'
import { Intent } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import Avatar from '../../components/Avatar/Avatar'
import GoogleButton from 'react-google-button'

import { Firebase } from '../../firebase/firebase'
import * as actions from '../../store/actions'

import './Login.css'
import BgDynamic from '../../components/BgDynamic/BgDynamic'
import MessageBoard from '../../components/MessageBoard/MessageBoard'

export class Login extends Component {

  constructor(props) {
    super(props)
    this.firebase = Firebase.getInstance()
    this.props = props
  }

  state = {
    email: '',
    password: '',
    user: this.props.user,
    messages: this.props.messages,
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user && !newProps.user.isEmpty && !newProps.user.role) {
      this.props.firebase
        .set('users/' + newProps.user.uid + '/role', 'user')
        .then(() => {
        })
        .catch((error) => {
          this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
        })
    }
    this.setState({
      user: newProps.user,
      messages: newProps.messages,
    })
  }

  checkLogin = (login) => {
    login
      .then((result) => {
        if (!result.user.email.endsWith('@unicornpowered.com')) {
          this.firebase.getFirebase().logout()
          this.props.onToast('warning', Intent.WARNING, 'Authorization failed. You must have a "unicornpowered.com" email.')
        }
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  onClickLoginEmail = (event) => {
    this.checkLogin(
      this.firebase.getFirebase().login({
        email: this.state.email,
        password: this.state.password
      }))
    this.props.onToast('warning', Intent.WARNING, 'Not yet supported.')
  }

  onClickLoginGoogle = (event) => {
    this.checkLogin(
      this.firebase.getFirebase().login({
        provider: 'google',
        type: 'popup'
      }))
  }

  updateEmailValue = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  updatePasswordValue = (event) => {
    this.setState({
      password: event.target.value
    })
  }

  handlerAddMessage = (message) => {
    this.props.firebase
      .push('messages/', {
        user_id: this.state.user.uid,
        user_display_name: this.state.user.displayName,
        user_avatar: this.state.user.avatarUrl,
        date: Date.now(),
        text: message
      })
      .then((result) => {
        const key = result.key
        this.props.firebase
          .set('messages/' + key + '/id', key)
          .then(() => {
            this.props.onToast('info-sign', Intent.SUCCESS, 'Message added!')
          })
          .catch((error) => {
            this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
          })
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  handlerDeleteMessage = (message) => {
    this.props.firebase
      .ref('messages/' + message.id)
      .remove()
      .then(() => {
        this.props.onToast('info-sign', Intent.SUCCESS, 'Message removed!')
      })
      .catch((error) => {
        this.props.onToast('error', Intent.DANGER, 'Error: ' + error.message)
      })
  }

  render() {
    const { messages, user } = this.state
    let container = null
    //simulate that there is a message to render divs in good order see css flexBox
    let messageContainer = (<div className="message"></div>)

    if (user && !user.isEmpty) {
      container = (
        <div>
          <h1 className="bp3-heading">Welcome!</h1>
          <center className="avatar-container"><Avatar round={true} src={user.avatarUrl} /></center>
          <p><b>{user.displayName}</b></p>
          <p>{user.email}</p>
        </div>
      )

      messageContainer = (
        <div className="message">
          {user && !user.isEmpty ? (
            <MessageBoard
              user={user}
              messages={messages}
              onAddMessage={this.handlerAddMessage}
              onDeleteMessage={this.handlerDeleteMessage} />) : (<div></div>)}
        </div>
      )

    }
    else {
      container = (
        <div>
          <h1 className="bp3-heading">Login - TimeSheet</h1>
          <form>
            <div className="bp3-input-group bp3-large">
              <span className="bp3-icon bp3-icon-person"></span>
              <input type="text" className="bp3-input" placeholder="Email" onChange={this.updateEmailValue} />
            </div>
            <div className="bp3-input-group bp3-large">
              <span className="bp3-icon bp3-icon-lock"></span>
              <input type="password" className="bp3-input" placeholder="Password" onChange={this.updatePasswordValue} />
            </div>
            <button type="button" className="bp3-button bp3-intent-success" onClick={this.onClickLoginEmail}>Login</button>
            <div className="google-button">
              <GoogleButton type="light" onClick={this.onClickLoginGoogle}></GoogleButton>
            </div>
          </form>
        </div>
      )
    }

    return (
      <div>
        <BgDynamic>
          <div className="loginbox">
            {messageContainer}
            <div className="loginmodal">
              {container}
            </div>
          </div>
        </BgDynamic>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.firebase.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onToast: (iconName, intent, message) => dispatch(actions.toast({ icon: iconName, intent: intent, message: message })),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps),
  firebaseConnect((props) => [
    'messages',
  ]),
  connect((state, props) => ({
    messages: state.firebase.data.messages,
  }))
)(Login)
