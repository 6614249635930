import moment from 'moment'
import { get } from 'lodash'
import DateUtils from './DateUtils'

export const getTimelogsForUsers = (users, firebase, tasks, beginDate, endDate) => {
  const promises = users.map((user) => getTimelogsForUser(user, firebase, tasks, beginDate, endDate))
  return Promise.all(promises)
}

export const getTimelogsForUser = (user, firebase, tasks, beginDate, endDate) => {
  const weeksDataPromises = getWeeksDataPromises(user, firebase, moment(beginDate), moment(endDate))

  return Promise.all(weeksDataPromises)
    .then((data) => {
      const concatData = []

      for (let i = 0; i < weeksDataPromises.length; i++) {
        const rawData = data[i].val()
        if (rawData) {
          Object.keys(rawData).map((key) => concatData.push(rawData[key]))
        }
      }
      return {
        ...user,
        timelogs: summariesResults(concatData, tasks, moment(beginDate), moment(endDate)).map((r) => ({
          ...r,
          user_id: user.uid,
          user_display_name: user.displayName,
        }))
      }
    })
}

const getWeeksDataPromises = (user, firebase, beginDate, endDate) => {
  const weeksData = []
  const currentDate = moment(beginDate)

  const diffWeek = endDate.startOf('isoweek').diff(beginDate.startOf('isoweek'), 'weeks')

  for (let i = 0; i <= diffWeek; i++) {
    weeksData.push(
      firebase.database().ref('/timelogs/' + currentDate.isoWeekYear() + '/' + currentDate.isoWeek() + '/' + user.uid).once('value')
    )
    currentDate.add(1, 'weeks')
  }

  return weeksData
}

const summariesResults = (data, tasks, beginDate, endDate) => {
  let results = []
  const startWeekData = []
  const endWeekData = []
  const inBetweenWeekData = []

  const startDiffInDays = beginDate.diff(moment(beginDate).startOf('isoWeek'), 'days')
  const endDiffInDays = moment(endDate).endOf('isoWeek').diff(endDate, 'days')

  const startWeek = beginDate.isoWeek()
  const startYear = beginDate.isoWeekYear()
  const endWeek = endDate.isoWeek()
  const endYear = endDate.isoWeekYear()

  data.forEach((d) => {
    if (d.week === startWeek && d.year === startYear) {
      startWeekData.push(d)
    }
    else if (d.week === endWeek && d.year === endYear) {
      endWeekData.push(d)
    }
    else {
      inBetweenWeekData.push(d)
    }
  })

  if (startWeek === endWeek && startYear === endYear) {
    startWeekData.forEach((weekData) => {
      results = addSumLine(results, weekData, tasks, startDiffInDays, 7 - endDiffInDays)
    })
  }
  else {
    startWeekData.forEach((weekData) => {
      results = addSumLine(results, weekData, tasks, startDiffInDays, 7)
    })

    inBetweenWeekData.forEach((weekData) => {
      results = addSumLine(results, weekData, tasks, 0, 7)
    })

    endWeekData.forEach((weekData) => {
      results = addSumLine(results, weekData, tasks, 0, 7 - endDiffInDays)
    })
  }
  return results
}

const addSumLine = (results, line, tasks, start, end) => {
  if (line.task_type !== undefined && line.task_id !== undefined) {
    if (line.hasOwnProperty('subtasks')) {
      const taskName = tasks.find((t) => t.id === line.task_id).name
      const sumSubtasks = line.subtasks.map((subtask) => {
        return sumLine(subtask, start, end)
      }).reduce((a, b) => a + b, 0)
      if (sumSubtasks) {
        results.push({
          task_type: line.task_type,
          task_id: line.task_id,
          task_name: taskName,
          is_subtask: false,
          sum: sumSubtasks,
          week: line.week,
          year: line.year
        })
        line.subtasks.forEach((subtask) => {
          const sumSubtask = sumLine(subtask, start, end)
          results.push({
            parent_task: taskName,
            task_type: line.task_type,
            task_id: line.task_id,
            subtask_id: subtask.subtask_id,
            is_subtask: true,
            task_name: `${taskName}/${subtask.name}`,
            sum: sumSubtask,
            week: subtask.week,
            year: subtask.year
          })
        })
      }
    }
    else {
      const sum = sumLine(line, start, end)
      const taskName = tasks.find((t) => t.id === line.task_id).name
      if (sum) {
        results.push({
          task_type: line.task_type,
          task_id: line.task_id,
          task_name: taskName,
          is_subtask: false,
          sum: sum,
          week: line.week,
          year: line.year
        })
      }
    }
  }
  return results
}

const sumLine = (line, start, end) => {
  let sum = 0
  const days = [
    line.monday,
    line.tuesday,
    line.wednesday,
    line.thursday,
    line.friday,
    line.saturday,
    line.sunday
  ]

  for (let i = start; i < end; ++i) {
    sum += getFloat(days[i])
  }
  return sum
}

export const groupTimelogsByTasks = (users = []) => {
  const groupedTimelogs = []
  const userTimelogs = users.map((user) => user.timelogs)
  const timelogs = flatten(userTimelogs)

  timelogs.forEach((timelog) => {
    const foundTimelog = findTimelogInGroupedTimelogs(timelog, groupedTimelogs)
    if (!foundTimelog) {
      const newGroupedTimelog = { ...timelog }
      delete newGroupedTimelog.year
      delete newGroupedTimelog.week
      groupedTimelogs.push(newGroupedTimelog)
    } else {
      foundTimelog.sum += timelog.sum
    }
  })

  return groupedTimelogs
}

const findTimelogInGroupedTimelogs = (timelog, groupedTimelogs) => {
  if (timelog && timelog.is_subtask) {
    return groupedTimelogs.find((groupedTimelog) => {
      return groupedTimelog.task_id === timelog.task_id && groupedTimelog.user_id === timelog.user_id && groupedTimelog.subtask_id === timelog.subtask_id
    })
  }
  return groupedTimelogs.find((groupedTimelog) => groupedTimelog.task_id === timelog.task_id && groupedTimelog.user_id === timelog.user_id)
}

export const formatTimelogsForGeneralReport = (showSubtasks, timelogs) => {
  const formattedTimelogs = createTimelogLinesForGeneralReport(showSubtasks, timelogs)
  if (formattedTimelogs.length) {
    const totalLine = createTotalLineForGeneralReport(showSubtasks, timelogs)
    formattedTimelogs.push(totalLine)
  }
  return formattedTimelogs
}

export const createTimelogLinesForGeneralReport = (showSubtasks, timelogs) => {
  return timelogs.filter((timelog) => !timelog.is_subtask || (timelog.is_subtask && showSubtasks)).map((timelog) => createTimelogLineForGeneralReport(showSubtasks, timelog))
}

export const createTimelogLineForGeneralReport = (showSubtasks, timelog) => {
    return {
      'User': timelog.user_display_name,
      'Type': timelog.task_type,
      'Tasks': timelog.task_name,
      'Total (h)': timelog.sum
    }
}

export const createTotalLineForGeneralReport = (showSubtasks, timelogs) => {
  return {
    'User': 'Total (h)',
    'Type': '',
    'Tasks': '',
    'Total (h)': calculateTotalForTimelogs(showSubtasks, timelogs),
  }
}

export const calculateTotalForTimelogs = (showSubtasks, timelogs) => {
  const tasks = getTasksListFromTimelogs(timelogs, showSubtasks)
  return tasks.reduce((acc, task) => acc + getTimeForTask(showSubtasks, timelogs, task), 0)
}

export const formatTimelogsForWeeklyReport = (timelogs, showSubtasks, beginDate, endDate) => {
  const formattedTimelogs = []

  const tasks = getTasksListFromTimelogs(timelogs, showSubtasks)
  const { startWeek, startYear, endWeek, endYear } = getIsoRangeInfo(beginDate, endDate)

  for (let year = startYear; year <= endYear; year++) {
    for (let week = getStartWeekIsoNumber(startWeek, startYear, year); week <= getEndWeekIsoNumber(endWeek, endYear, year); week++) {
      const weekTimelogs = filterTimelogsForWeek(timelogs, week, year)
      const weekline = createWeekLine(showSubtasks, weekTimelogs, tasks, week, startYear !== endYear ? year : null)
      formattedTimelogs.push(weekline)
    }
  }
  const totalLine = createTotalLine(formattedTimelogs, tasks)
  formattedTimelogs.push(totalLine)
  return formattedTimelogs
}

export const getTasksListFromTimelogs = (timelogs, showSubtasks) => {
  const tasks = []
  if (showSubtasks) {
    timelogs.forEach((timelog) => {
      const isASubtask = timelog.is_subtask
      if (isASubtask || isTaskWithoutSubtask(timelog, timelogs)) {
        tasks.push({
          id: timelog.task_id,
          subtask_id: timelog.subtask_id,
          name: timelog.task_name,
          type: timelog.task_type,
          is_subtask: isASubtask
        })
      }
      else if (!taskAlreadyExists(tasks, timelog)){
        tasks.push({
          id: timelog.task_id,
          subtask_id: timelog.subtask_id,
          name: timelog.task_name,
          type: timelog.task_type,
          is_subtask: isASubtask
        })
      }
    })
  } else {
    timelogs.forEach((timelog) => {
      if (!taskAlreadyExists(tasks, timelog)) {
        tasks.push({
          id: timelog.task_id,
          name: timelog.task_name,
          type: timelog.task_type,
          is_subtask: false
        })
      }
    })
  }
  return tasks
}

const isTaskWithoutSubtask = (timelog, timelogs) => {
  return timelogs.filter((tl) => tl.task_id === timelog.task_id).length === 1
}

export const taskAlreadyExists = (tasks, timelog) => tasks.find((task) => task.id === timelog.task_id)

export const getStartWeekIsoNumber = (startWeek, startYear, year) => year === startYear ? startWeek : DateUtils.MIN_ISO_WEEK_NUMBER

export const getEndWeekIsoNumber = (endWeek, endYear, year) => year === endYear ? endWeek : DateUtils.getIsoWeeksInYear(year)

export const filterTimelogsForWeek = (timelogs, week, year) => {
  return timelogs.filter((timelog) => timelog.year === year && timelog.week === week)
}

export const createWeekLine = (showSubtasks, timelogs, tasks, week, year) => {

  const weekline = {
    'Weeks': year ? `${year}/${week}` : week
  }
  tasks.forEach((task) => {
    weekline[task.name] = getTimeForTask(showSubtasks, timelogs, task)
  })
  weekline['Total (h)'] = calculateTotalForLine(weekline, tasks)

  return weekline
}

export const minusSubTasks = (showSubtasks, timelog, task, timelogs) => {
  return timelogs.filter((element) => task.name === element.parent_task).reduce((acc, task) => acc -= getTimeForTask(showSubtasks, timelogs, task), timelog[0].sum)
}

export const getTimeForTask = (showSubtasks, timelogs, task) => {
  if(showSubtasks && task.is_subtask) {
      const timelog = timelogs.filter((timelog) => timelog.subtask_id === task.subtask_id)
      return timelog.length > 0 ? timelog[0].sum : 0
    }
  const timelog = timelogs.filter((timelog) => timelog.task_id === task.id)
  return timelog.length > 0 ? (showSubtasks ? minusSubTasks(showSubtasks, timelog, task, timelogs) : timelog[0].sum) : 0

}

export const calculateTotalForLine = (weekline, tasks) => {
  return tasks.reduce((acc, task) => acc + weekline[task.name], 0)
}

export const createTotalLine = (weeklines, tasks) => {
  const totalLine = {
    'Weeks': 'Total (h)'
  }
  tasks.forEach((task) => {
    totalLine[task.name] = calculateTotalForTask(weeklines, task)
  })
  totalLine['Total (h)'] = calculateTotalForLine(totalLine, tasks)

  return totalLine
}

export const calculateTotalForTask = (weeklines, task) => {
  return weeklines.reduce((acc, weekline) => acc + weekline[task.name], 0)
}

export const formatTitle = (name, beginDate, endDate) => {
  return `${name} - ${moment(beginDate).format(DateUtils.SHORT_DATE_FORMAT)} to ${moment(endDate).format(DateUtils.SHORT_DATE_FORMAT)}`
}

export const getFloat = (value) => {
  return (value && !isNaN(value)) ? parseFloat(value) : 0
}

export const flatten = (arrays) => {
  return [].concat.apply([], arrays)
}

export const toTitleCase = (str) => {
  return str ?
    str.split(' ')
      .map((w) => w[0].toUpperCase() + w.substr(1))
      .join(' ') :
    ''
}

export const getIsoRangeInfo = (beginDate, endDate) => {
  return {
    startWeek: moment(beginDate).isoWeek(),
    startYear: moment(beginDate).isoWeekYear(),
    endWeek: moment(endDate).isoWeek(),
    endYear: moment(endDate).isoWeekYear()
  }
}

// Immutable
export const updateObjectInArray = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item
    }
  })
}

export const getObjectValues = (ojb) => {
  return Object.keys(ojb || {}).map((key) => ojb[key])
}

export const getTasks = (tasks) => {
  return tasks ?
    getObjectValues(tasks.task_types)
      .map((taskType) => getObjectValues(tasks[taskType]))
      .reduce((a, b) => a.concat(b), []) // Flatten arrays
    : []
}

export const getActiveUsers = (users) => {
  return users ?
    getObjectValues(users)
      .filter((user) => user.active === true || user.active === undefined)
    : []
}

export const getUsers = (users) => {
  return users ?
    getObjectValues(users)
    : []
}

export const getSubtasks = (subtasks) => {
  return subtasks ?
    subtasks
    : []
}

export const sumOfSubtasksForDay = (timelog, day) => {
  return timelog.subtasks.map((subtask) => {
    return getFloat(subtask[day])
  }).reduce((a, b) => a + b, 0)
}

export const hasSubtasks = (timelog) => {
  return get(timelog, 'subtasks', []).length > 0
}

export const convertArrayOfObjectsToCSV = (args) => {
  let result, ctr

  const data = args && args.data
  if (!data || !data.length) {
    return
  }

  const columnDelimiter = args.columnDelimiter || ','
  const lineDelimiter = args.lineDelimiter || '\n'
  const keys = args.keys || Object.keys(data[0])

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach((item) => {
    ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) { result += columnDelimiter }

      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export const downloadCSV = (data, keys, filename = 'export.csv') => {
  let csv = convertArrayOfObjectsToCSV({
    data: data,
    keys: keys,
  })

  if (csv) {
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv
    }

    const link = document.createElement('a')
    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
  }
}

export const orderAscendingArray = (array, key) => {
  return array.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
}

export const orderDescendingArray = (array, key) => {
  return array.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
}
