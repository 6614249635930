import React, { Component } from 'react'
import Avatar from '../Avatar/Avatar'
import moment from 'moment'
import { Button, Icon, Position, Tooltip } from '@blueprintjs/core'

import './TimesheetComment.css'

import DateUtils from '../../DateUtils'
import Comment from '../Comment/Comment'
import MessageEditor from '../MessageEditor/MessageEditor'

class TimesheetComment extends Component {

  state = {
    comments: (this.props.comments) ? Object.keys(this.props.comments).map((i) => this.props.comments[i]) : [],
    user: this.props.user,
    onAddComment: this.props.onAddComment,
    onDeleteComment: this.props.onDeleteComment,
    commentText: ''
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      comments: (newProps.comments) ? Object.keys(newProps.comments).map((i) => newProps.comments[i]) : [],
      onAddComment: newProps.onAddComment,
      user: newProps.user
    })
  }

  handlerUpdateCommentText = (message) => {
    this.setState({
      commentText: message
    })
  }

  handlerOnSaveComment = () => {
    this.state.onAddComment(this.state.commentText)
    this.setState({
      commentText: ''
    })
  }

  handlerOnDeleteComment = (comment) => {
    this.state.onDeleteComment(comment)
  }

  render() {
    const comments = this.state.comments
    return (
      <div>
        <div className="box-commentary">
          {(comments && comments.length > 0) ? (
            <div className="comment">
              {comments.map((comment, index) => {
                return (
                  <div key={index} className="comment">
                    <div className="bp3-card bp3-elevation-2 comment">
                      <table width="100%">
                        <tbody>
                          <tr>
                            {comment.user_avatar ? (<td width="10px">
                              <Avatar round={true} src={comment.user_avatar} size={30} />
                            </td>) : null}
                            <td>
                              <ul style={{ listStyleType: 'none' }}>
                                <li className="bp3-text-muted">
                                  {comment.user_display_name ? (<i>{comment.user_display_name} commented <Tooltip content={moment(comment.date).format(DateUtils.LONG_DATE_FORMAT)} position={Position.TOP}>{moment(comment.date).fromNow()}</Tooltip></i>) :
                                    null}
                                </li>
                              </ul>
                            </td>
                            {(comment.user_id === this.state.user.uid || (!(comment.user_id) && this.state.user.role === 'admin')) ? (
                              <td width="10px">
                                <Button icon="trash" onClick={() => this.handlerOnDeleteComment(comment)}></Button>
                              </td>) : null}
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <Comment message={comment.text} />
                    </div>
                  </div>
                )
              })}
            </div>
          ) : null}

          <div className="bp3-card edit-comment">
            <table width="100%">
              <tbody>
                <tr>
                  <td width="50px" valign="top">
                    <Avatar round={true} src={(this.state.user) ? this.state.user.avatarUrl : ''} size={30} />
                  </td>
                  <td>
                    <p><Icon icon="inbox" iconSize={18} /> Write a comment</p>
                    <MessageEditor onSaveMessage={this.handlerOnSaveComment} onMessageChange={this.handlerUpdateCommentText} />
                    <div className="edit-comment">
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default TimesheetComment