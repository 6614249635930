import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { withRouter, Switch } from 'react-router-dom'

import * as actions from '../../store/actions'
import { getActiveUsers } from '../../Utils'
import Select from '../../components/Select/Select'
import Userinfo from '../../components/Userinfo/Userinfo'
import Tabs from '../../components/Tabs/Tabs'
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute'
import Time from './Time/Time'
import Summary from './Summary/Summary'

import './Timesheet.css'

export class Timesheet extends Component {

  constructor(props) {
    super(props)
    this.props = props
  }

  state = {
    user: this.props.user,
    timesheetUser: (this.props.timesheetUser) ? this.props.timesheetUser : this.props.user,
    users: this.props.users,
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      user: newProps.user,
      timesheetUser: (newProps.timesheetUser) ? newProps.timesheetUser : newProps.user,
      users: newProps.users,
    })
  }

  handlerSelectUserChange = (event) => {
    const user = this.state.users[event.target.value]
    if (user && user.uid !== '') {
      this.props.onSelectUser(user)
    }
    else {
      this.props.onSelectUser(null)
    }
  }

  render() {
    const links = [
      { title: 'Timesheet', location: '/timesheet' },
      { title: 'Expenses Reports', location: '/timesheet/expenses' },
      { title: 'Summary', location: '/timesheet/summary' }
    ]

    const userOptions = this.state.users.map((user, index) => {
      return {
        text: user.displayName + ' - ' + user.email,
        value: index,
      }
    })
    const userValue = this.state.users.findIndex((user) => user.uid === this.state.timesheetUser.uid)

    return (
      <div className="dashboard-box">
        <div className="user-info-container">
          <div>
            <Userinfo user={this.state.user} />
          </div>
          <div>
            {this.state.user.role === 'admin' || this.state.user.role === 'manager' ? (
              <Select
                labelText="View as:"
                defaultText="Select a user ..."
                options={userOptions}
                value={userValue}
                onChange={this.handlerSelectUserChange} />
            ) : null}
          </div>
        </div>

        <Tabs links={links}>
          <Switch>
            <PrivateRoute authed={this.state.user} path="/timesheet" exact component={Time} />
            <PrivateRoute authed={this.state.user} path="/timesheet/summary" component={Summary} />
          </Switch>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.firebase.profile,
    timesheetUser: state.timesheet.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectUser: (user) => dispatch(actions.selectUser({ user: user })),
  }
}

export default withRouter(compose(
  connect(
    mapStateToProps,
    mapDispatchToProps),
  firebaseConnect((props) => [
    'users',
  ]),
  connect((state, props) => ({
    users: getActiveUsers(state.firebase.data.users),
  }))
)(Timesheet))
