import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Title.css'

class Title extends Component {

  state = {
    version: 'not official release'
  }

  componentWillMount() {
    const version = process.env.REACT_APP_VERSION

    if (version) {
      this.setState({
        version: version
      })
    }
  }

  render() {
    return (
      <div><div className="bp3-navbar-heading title"><Link className="title" to="/">UnicornPowered - Hub<span className="bp3-text-muted version"> v.{this.state.version}</span></Link></div> </div>
    )
  }
}

export default Title