import React, { Component } from 'react'
import Avatar from '../Avatar/Avatar'
import moment from 'moment'
import { Button, Position, Tooltip } from '@blueprintjs/core'
import DateUtils from '../../DateUtils'
import Comment from '../Comment/Comment'

class AchievementComment extends Component {

  state = {
  }

  handlerOnDeleteComment = () => {
    this.props.onDeleteComment(this.props.comment, this.props.id)
  }

  render() {
    const { comment } = this.props
    return (
      <div style={{ marginBottom: '20px', marginTop: '10px', marginLeft: '35px', marginRight: '15px' }}>
        <div className="row">
          <div className="col-11">
            <div style={{ fontSize: '11px' }}>
              <Avatar round={true} src={(comment.user_avatar) ? comment.user_avatar : ''} size={18} style={{ marginRight: '5px' }} />{comment.user_display_name} commented <Tooltip content={moment(comment.date).format(DateUtils.LONG_DATE_FORMAT)} position={Position.TOP}>{moment(comment.date).fromNow()}</Tooltip>
            </div>
            <div style={{ marginLeft: '23px' }}>
              <Comment message={comment.text} />
            </div>
          </div>
          <div className="col-1">
            {(comment.user_id === this.props.user.uid || this.props.user.role === 'admin') &&
              <Button icon="trash" onClick={() => this.handlerOnDeleteComment(comment)}></Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default AchievementComment